import { DonutChart } from "@mantine/charts";
import { Group, Stack, Text, Title } from "@mantine/core";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm } from "src/gql/graphql";

export const InsightsChart = (props: { msgId: string; cm?: Cm }) => {
    const { data } = useSuspenseQuery(query(props.msgId, props.cm));

    const successRate = ((data.successCount / data.metricsCount) * 100).toFixed(1);
    const failureRate = ((data.failedCount / data.metricsCount) * 100).toFixed(1);
    const pendingRate = ((data.pendingCount / data.metricsCount) * 100).toFixed(1);

    return (
        <Group align="flex-start">
            <DonutChart
                size={80}
                thickness={8}
                withTooltip={false}
                data={[
                    { name: "Success", value: data.successCount, color: "green" },
                    { name: "Failure", value: data.failedCount, color: "red" },
                    { name: "Pending", value: data.pendingCount, color: "gray" },
                ]}
            />
            <Stack gap={0}>
                <Title order={3}>{`${props.cm ?? "ALL"} ${data.metricsCount}`}</Title>
                <Text size="sm">{`Success: ${data.successCount} (${successRate}%)`}</Text>
                <Text size="sm">{`Failure: ${data.failedCount} (${failureRate}%)`}</Text>
                <Text size="sm">{`Undetermined: ${data.pendingCount} (${pendingRate}%)`}</Text>
            </Stack>
        </Group>
    );
};

const query = (msgId: string, cm?: Cm) =>
    queryOptions({
        queryKey: qk("messages", "insights", "chart", cm ?? "ALL", msgId),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ChartInsight($id: UUID!, $cm: CM) {
                        message(id: $id) {
                            insights(cm: $cm) {
                                successCount
                                failedCount
                                pendingCount
                                metricsCount
                            }
                        }
                    }
                `),
                { id: msgId, cm },
            ),
        select: ({ message }) => message.insights,
    });
