import { queryOptions } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { TopicLikeFragment } from "src/gql/graphql";

/** @public */
export function useCreateTopic(onSuccess: () => void) {
    const { userId } = useUserId();
    const { mutate: createTopic } = useGraphqlMutation({
        document: mutations.createTopic,
        onSuccess: async (data) => {
            const parentId = data?.createTopic.parent?.id;
            await Promise.all([
                parentId && (await qk.invalidate("topic", "id", parentId, "children")),
                await qk.invalidate("user", "id", userId),
            ]);
            onSuccess();
            notify.show.success({ message: "Topic created successfully" });
        },
    });
    return { createTopic };
}

/** @public */
export function useCreateCategory() {
    const { mutate: createCategory } = useGraphqlMutation({
        document: mutations.createCategory,
        onSuccess: async (data) => {
            const parentId = data?.createTopic.parent?.id;
            await Promise.all([qk.invalidate("user"), !!parentId && qk.invalidate("topic", "id", parentId)]);
            notify.show.success({ message: "Category created successfully" });
        },
    });
    return { createCategory };
}

/** @public */
export function useUpdateTopic() {
    const { mutateAsync: updateTopicAsync } = useGraphqlMutation({
        document: mutations.topicUpdate,
        onSuccess: async (data) => {
            const topicId = data?.updateTopic.id;
            topicId && (await qk.invalidate("topic", "id", topicId, "details"));
        },
    });
    return { updateTopicAsync };
}

/** @public */
export function useUpdateTopicParent() {
    const { mutateAsync: updateTopicParentAsync } = useGraphqlMutation({
        document: mutations.topicParentUpdate,
        onSuccess: async () => {
            await Promise.all([qk.invalidate("topic"), qk.invalidate("user")]);
        },
    });
    return { updateTopicParentAsync };
}

/** @public */
export function useAddUserToTopic() {
    const { userId } = useUserId();
    const { mutate: addUser } = useGraphqlMutation({
        document: mutations.addUser,
        onSuccess: async (data) => {
            const topicId = data?.addUserToTopic.id;
            await Promise.all([!!topicId && qk.invalidate("topic", "id", topicId, "members"), qk.invalidate("user", "id", userId)]);
            notify.show.success({ message: "Subscribed to topic" });
        },
    });
    return { addUser };
}

/** @public */
export function useRemoveUserFromTopic() {
    const { userId } = useUserId();
    const { mutate: removeUser } = useGraphqlMutation({
        document: mutations.removeUser,
        onSuccess: async (data) => {
            const topicId = data?.removeUserFromTopic.id;
            notify.show.success({ message: "Unsubscribed from topic" });
            await Promise.all([!!topicId && qk.invalidate("topic", "id", topicId, "members"), qk.invalidate("user", "id", userId)]);
        },
    });
    return { removeUser };
}

/** @public */
export const topicsQueries = {
    topicFragment: graphql(`
        fragment TopicLike on TopicNode {
            isCategory
            isRequired
            id
            name
            description
        }
    `),
    rootTopicQuery: graphql(`
        query RootTopic {
            rootTopic {
                ...TopicLike
                children {
                    ...TopicLike
                }
            }
        }
    `),
    topicQuery: graphql(`
        query Topic($id: UUID!) {
            topic(topicId: $id) {
                ...TopicLike
                children {
                    id
                }
                parent {
                    id
                    name
                }
            }
        }
    `),
    topicChildrenQuery: graphql(`
        query TopicChildren($id: UUID!) {
            topic(topicId: $id) {
                children {
                    ...TopicLike
                }
            }
        }
    `),
    topicMembersQuery: graphql(`
        query TopicMembers($id: UUID!) {
            topic(topicId: $id) {
                members {
                    id
                }
            }
        }
    `),
};

/** @public */
export const topicsQueryOptions = {
    rootTopic: queryOptions({
        queryFn: () => httpPostGraphql(topicsQueries.rootTopicQuery, {}),
        queryKey: qk("topic", "rootTopic"),
        select: ({ rootTopic }) => {
            const { children, ...rest } = rootTopic;
            return {
                rootTopic: {
                    children: children as TopicLikeFragment[],
                    ...(rest as TopicLikeFragment),
                },
            };
        },
    }),
    topic: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicQuery, { id }),
            queryKey: qk("topic", "id", id, "details"),
            select: ({ topic }) => {
                const { children, parent, ...rest } = topic;
                return {
                    topic: {
                        children: children as TopicLikeFragment[],
                        parent,
                        ...(rest as TopicLikeFragment),
                    },
                };
            },
        }),
    topicChildren: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicChildrenQuery, { id }),
            queryKey: qk("topic", "id", id, "children"),
            select: ({ topic }) => ({ children: topic.children as TopicLikeFragment[] }),
        }),
    topicMembers: (id: string) =>
        queryOptions({
            queryFn: () => httpPostGraphql(topicsQueries.topicMembersQuery, { id }),
            queryKey: qk("topic", "id", id, "members"),
            select: ({ topic }) => ({ members: topic.members }),
        }),
};

const mutations = {
    topicUpdate: graphql(`
        mutation UpdateTopic($topicId: UUID!, $params: UpdateTopicParametersInput!) {
            updateTopic(id: $topicId, params: $params) {
                id
            }
        }
    `),
    topicParentUpdate: graphql(`
        mutation UpdateTopicParent($topicId: UUID!, $parentId: UUID!) {
            changeTopicParent(id: $topicId, parentId: $parentId) {
                id
            }
        }
    `),
    createTopic: graphql(`
        mutation CreateTopic($params: CreateTopicParametersInput!) {
            createTopic(params: $params) {
                parent {
                    id
                }
            }
        }
    `),
    createCategory: graphql(`
        mutation CreateCategory($title: String!, $parent: UUID!) {
            createTopic(params: { name: $title, isCategory: true, isRequired: false, parentId: $parent }) {
                parent {
                    id
                }
            }
        }
    `),
    addUser: graphql(`
        mutation AddUserToTopic($topicId: UUID!, $userId: UUID!) {
            addUserToTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
    removeUser: graphql(`
        mutation RemoveUserFromTopic($topicId: UUID!, $userId: UUID!) {
            removeUserFromTopic(topicId: $topicId, userId: $userId) {
                id
            }
        }
    `),
};
