type Maybe<T> = Some<NonNullable<T>> | (T extends T ? (T extends null | undefined ? null : never) : never);

export function maybe<T>(inner: T): Maybe<T> {
    if (inner == null) return null as Maybe<T>;
    else return new Some(inner) as Maybe<T>;
}

export function some<T>(inner: T): Some<T> {
    return new Some(inner);
}

/** @private */
abstract class Wrapper<T> {
    constructor(protected inner: T) {}

    take<F extends (it: T) => unknown>(fn: F): ReturnType<F>;
    take(): T;

    take<E = T>(mapping?: (it: T) => E): T | E {
        return typeof mapping == "function" ? mapping(this.inner) : this.inner;
    }
}

/** @private */
class Some<T> extends Wrapper<T> {
    map<E>(fn: (it: T) => E): Maybe<E> {
        return maybe(fn(this.inner));
    }

    takeIf(predicate: (it: T) => boolean): T | null {
        if (predicate(this.inner)) return this.inner;
        else return null;
    }

    if(predicate: (it: T) => boolean): Some<T> | null {
        if (predicate(this.inner)) return this;
        else return null;
    }

    run(fn: (it: T) => unknown): Some<T> {
        fn(this.inner);
        return this;
    }

    binding<const K extends string>(k: K): Bind<Record<K, T>> {
        return new Bind({ [k]: this.inner }) as Bind<Record<K, T>>;
    }
}

/** @private */
class Bind<T> extends Wrapper<T> {
    bind<const K extends string, E>(k: K, other: E): Bind<T & Record<K, E>> | undefined {
        if (!other) return undefined;
        return new Bind({ ...this.inner, [k]: other }) as Bind<T & Record<K, E>>;
    }
}
