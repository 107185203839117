import { createStore } from "zustand";

/**
 * Constructs a trival mapping function from an object to a given key
 *
 * @example
 * ```ts
 * const objects: { id: string, name: string[] } = getItems();
 *
 * const ids = objects.map(to("id"));
 * const names = obejcts.map(to("name"));
 * ```
 */
export function to<T, K extends keyof T>(k: K): (t: T) => T[K] {
    return (t: T) => t[k];
}

/**
 * Runs the given function, absorbing any errors if thrown
 */
export function catching<R>(f: () => R): R | undefined {
    try {
        return f();
    } catch {}
}

/**
 * Constructs a filter predicate which dedups an array of objects by some given key
 */
export function uniqueBy<T extends object, K extends keyof T>(k: K): (t: T) => boolean;
export function uniqueBy<T extends object, S>(select: (t: T) => S): (t: T) => boolean;
export function uniqueBy<T extends object, K extends keyof T, S>(disc: K | ((t: T) => S)): (t: T) => boolean {
    const seen = new Set<T[K] | S>();
    return (item: T) => {
        const selected = typeof disc == "function" ? disc(item) : item[disc];
        if (seen.has(selected)) return false;
        seen.add(selected);
        return true;
    };
}

/**
 * Constructs a debounce facilitator for executing anonymous, arbitrary blocks
 *
 * @example
 * ```ts
 * const { debouncing } = createDebounce();
 *
 * function handleClick() {
 *      console.log("this will always run")
 *      debouncing(() => {
 *          // this part is debounced!
 *      })
 * }
 * ```
 */
export function createDebounce(delayMs = 300) {
    const s = createStore<{ lastExecTime: number | null; timeoutId: number | null; didExec: () => void }>()((set) => ({
        lastExecTime: null,
        timeoutId: null,
        didExec: () => set({ lastExecTime: Date.now(), timeoutId: null }),
    }));

    return {
        debouncing: function (fn: () => void) {
            const now = Date.now();
            const { lastExecTime, timeoutId, didExec } = s.getState();

            if (lastExecTime && now - lastExecTime < delayMs) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }

                s.setState({
                    timeoutId: +setTimeout(
                        () => {
                            fn();
                            didExec();
                        },
                        delayMs - (now - lastExecTime),
                    ),
                });
            } else {
                fn();
                didExec();
            }
        },
    };
}

export function fail<R = never>(err: unknown): NonNullable<R> {
    if (err instanceof Error) throw err;
    else throw new Error(JSON.stringify(err));
}

export function voiding(f: Function): () => void {
    return () => {
        f();
    };
}

export function titlecase(s: string) {
    return s
        .split(/\s/)
        .map((each) => each.at(0)?.toLocaleUpperCase() + each.slice(1).toLocaleLowerCase())
        .join(" ");
}

/**
 * Constructs a random 13 character lowercase hexdecimal string
 *
 * @example
 * ```ts
 * createRandomStr(); // "e543d92cd3dcd"
 * ```
 */
export function createRandomStr(): string {
    return Math.random().toString(16).slice(2);
}

export function settlePromises({
    promises,
    onSuccess,
    onSomeFailed,
    onAllFailed,
}: {
    promises: Promise<unknown>[];
    onSuccess?: (() => void) | (() => Promise<unknown>);
    onAllFailed?: (() => void) | (() => Promise<unknown>);
    onSomeFailed?: ((numFailed: number) => void) | ((numFailed: number) => Promise<unknown>);
}) {
    return Promise.allSettled(promises).then((results) => {
        const failed = results.filter((res) => res.status == "rejected");
        if (results.length == failed.length) return onAllFailed?.();
        else if (failed.length > 0) return onSomeFailed?.(failed.length);
        else return onSuccess?.();
    });
}
