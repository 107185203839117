import { Button, Group, Loader, Title, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useParams } from "@tanstack/react-router";
import { Suspense } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { SplitButton } from "shared/components/global/SplitButton";
import {
    ComposeFormProvider,
    composeFormStateToMessageInfoInput,
    createComposeFormState,
    useComposeForm,
} from "shared/components/message/compose/context";
import { SendPanel } from "shared/components/message/compose/SendPanel";
import { useComposeFormPermissionChecker } from "shared/components/message/compose/useComposeFormPermissionChecker";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { MessageStatusBadge } from "shared/components/message/MessageStatusBadge";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { voiding } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { MessageStatus } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const nav = useNavigate();
    const { id } = useParams({ from: "/_auth/messages/drafts/$id" });
    const {
        data: { message },
    } = useSuspenseQuery(query(id));

    const { mutateAsync: saveDraft } = useGraphqlMutation(mutations.saveDraft);
    const { mutateAsync: sendMessage } = useGraphqlMutation(mutations.sendMessage);

    function handleSaveDraft() {
        const validation = form.validate();
        if (validation.hasErrors) {
            logger.warn(validation.errors);
            return;
        }

        void saveDraft({
            id,
            params: composeFormStateToMessageInfoInput.parse(form.getValues()),
        })
            .then((it) => qk.invalidate("messages", "composer", "message", it.updateMessage.id).then(() => it))
            .then(({ updateMessage: { subject } }) => notify.show.success({ message: `Saved draft '${subject}'.` }));
    }

    async function handleSend() {
        if (form.validate().hasErrors) return;
        const params = composeFormStateToMessageInfoInput.parse(form.getValues());
        await saveDraft({ id, params });

        modals.open({
            title: "Are you sure?",
            children: (
                <Suspense fallback={<Loader />}>
                    <SendPanel
                        msgId={id}
                        onClose={() => modals.closeAll()}
                        onSend={({ expireAt }) => {
                            sendMessage({ id, expireAt })
                                .then(({ sendMessage: { id } }) => nav({ to: "/messages/$id", params: { id } }))
                                .then(() => notify.show.success({ message: "Message sent!" }))
                                .then(() => modals.closeAll())
                                .catch(notify.catch);
                        }}
                    />
                </Suspense>
            ),
        });
    }

    const form = useComposeForm({
        initialValues: createComposeFormState(message),
    });

    const permissionChecker = useComposeFormPermissionChecker(form);

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>Edit Message</Title>
                <MessageStatusBadge status={MessageStatus.Draft} />
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Tooltip {...permissionChecker.getTooltipProps()}>
                <Group justify="flex-end" wrap="nowrap">
                    <Button variant="transparent" onClick={handleSaveDraft} disabled={permissionChecker.hasErrors}>
                        Save
                    </Button>

                    <SplitButton onClick={voiding(handleSend)} menu={{ Schedule: { fn: () => {} } }} disabled={permissionChecker.hasErrors}>
                        Review and send
                    </SplitButton>
                </Group>
            </Tooltip>
        </Content>
    );
};

const query = (id: string) =>
    queryOptions({
        queryKey: qk("messages", "composer", "message", id),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ComposeMessage($id: UUID!) {
                        message(id: $id) {
                            id
                            ...ComposeDetails
                        }
                    }
                `),
                { id },
            ),
    });

const mutations = {
    saveDraft: graphql(`
        mutation DraftsSave($id: UUID!, $params: MessageInfoInput!) {
            updateMessage(id: $id, params: $params) {
                id
                subject
            }
        }
    `),
    sendMessage: graphql(`
        mutation DraftsSendMesage($id: UUID!, $expireAt: Instant) {
            sendMessage(id: $id, expireAt: $expireAt) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/drafts/$id")({
    loader: ({ params: { id } }) => queryClient.ensureQueryData(query(id)),
    component: Component,
});
