import { Box, Button, Divider, Group, Input, Radio, Select, Skeleton, Stack, Text, TextInput } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Section, SectionProvider } from "shared/components/global/Section";
import { AttachmentEditor } from "shared/components/message/compose/AttachmentEditor";
import { AudienceGroupEditor } from "shared/components/message/compose/AudienceGroupsEditor";
import { AudienceTopicEditor } from "shared/components/message/compose/AudienceTopicEditor";
import { ComposeFormProvider, useComposeFormContext } from "shared/components/message/compose/context";
import { CustomizationEditor } from "shared/components/message/compose/CustomizationEditor";
import { EscalationEditor } from "shared/components/message/compose/EscalationEditor";
import { GeoLocationEditor } from "shared/components/message/compose/GeoLocationEditor";
import { MessageBodyEditor } from "shared/components/message/compose/MessageBodyEditor";
import { SmsPreview } from "shared/components/message/compose/SmsPreview";
import { useComposeFormSmsPreview } from "shared/components/message/compose/useComposeFormSmsPreview";
import { useAside } from "shared/utils/aside";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { MessageSeverity } from "src/gql/graphql";

// TODO: when geo location becomes a thing again, remove this and implement it properly
void GeoLocationEditor;

export const ComposeForm = () => {
    const form = useComposeFormContext();
    const aside = useAside();
    const { smsPreview } = useComposeFormSmsPreview();

    const {
        data: { senders },
    } = useSuspenseQuery(queries.senders);

    return (
        <SectionProvider>
            <Stack>
                <Stack mb="md">
                    <Select
                        searchable
                        withAsterisk
                        label="From"
                        data={senders.map(({ id: value, name: label }) => ({ value, label }))}
                        {...form.getInputProps("sender.id", { withError: true })}
                    />
                    <Radio.Group label="Criticality" withAsterisk {...form.getInputProps("severity")}>
                        <Group mt="xs">
                            <Radio label="Informational" value={MessageSeverity.Informational} />
                            <Radio label="Urgent" value={MessageSeverity.Urgent} />
                        </Group>
                    </Radio.Group>
                    <Button
                        variant="default"
                        display={form.values.severity != MessageSeverity.Urgent ? "none" : ""}
                        onClick={() =>
                            aside.present({
                                label: "Escalation Policy",
                                component: (
                                    <ComposeFormProvider form={form}>
                                        <EscalationEditor />
                                    </ComposeFormProvider>
                                ),
                            })
                        }
                    >
                        Escalation Policy
                    </Button>
                </Stack>
            </Stack>

            <Section label="Audience">
                <AudienceGroupEditor />
                <AudienceTopicEditor />
            </Section>
            <Section label="Message">
                <TextInput withAsterisk label="Subject" {...form.getInputProps("subject")} />
                <Input.Wrapper withAsterisk label="Body">
                    <Box
                        style={{
                            border: "solid 1px var(--mantine-color-default-border)",
                            borderRadius: "6px",
                        }}
                    >
                        <MessageBodyEditor />
                    </Box>
                </Input.Wrapper>

                <CustomizationEditor />

                <Group justify="space-between">
                    <Group gap={"xs"}>
                        <Text fw="bold" c={smsPreview.length > 1400 ? "red" : ""}>
                            SMS Characters Remaining: {1400 - smsPreview.length}
                        </Text>

                        {smsPreview.length > 1400 && (
                            <Text c="red" mb={-4}>
                                <IconAlertCircle size={16} />
                            </Text>
                        )}
                    </Group>
                    <Button
                        variant="default"
                        onClick={() =>
                            aside.present({
                                label: "SMS Preview",
                                component: (
                                    <ComposeFormProvider form={form}>
                                        <SmsPreview />
                                    </ComposeFormProvider>
                                ),
                            })
                        }
                    >
                        SMS Preview
                    </Button>
                </Group>

                <Suspense
                    fallback={
                        <Group>
                            {[200, 50, 150, 170, 120, 250].map((w, i) => (
                                <Skeleton key={i} h="lg" w={w} />
                            ))}
                        </Group>
                    }
                >
                    <AttachmentEditor />
                </Suspense>

                <Divider />
            </Section>
        </SectionProvider>
    );
};

const queries = {
    senders: queryOptions({
        queryKey: qk("compose", "senders"),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query ComposeFormSenders {
                        senders {
                            id
                            name
                        }
                    }
                `),
                {},
            ),
    }),
};
