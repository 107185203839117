import { Box, BoxProps, Loader, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { DefaultError, QueryKey, useSuspenseInfiniteQuery, UseSuspenseInfiniteQueryOptions } from "@tanstack/react-query";
import { useInView } from "motion/react";
import { useEffect, useRef } from "react";

type LoadMoreTriggerProps<TQueryFnData, TData extends { pages: unknown[][] }, TQueryKey extends QueryKey> = {
    query: UseSuspenseInfiniteQueryOptions<TQueryFnData, DefaultError, TData, TQueryFnData, TQueryKey, number>;
} & Omit<BoxProps, "ref">;

export const LoadMoreTrigger = <TQueryFnData, TData extends { pages: unknown[][] }, TQueryKey extends QueryKey>({
    query,
    ...rest
}: LoadMoreTriggerProps<TQueryFnData, TData, TQueryKey>) => {
    const { hasNextPage, fetchNextPage, isFetchingNextPage, hasPreviousPage, isFetching } = useSuspenseInfiniteQuery(query);
    const [debouncedIsFetchingNextPage] = useDebouncedValue(isFetchingNextPage, 100);

    const ref = useRef(null);
    const isInView = useInView(ref);

    useEffect(() => {
        if (!isInView) return;
        if (hasNextPage) void fetchNextPage();
    }, [isInView, isFetching]);

    return (
        <Box ref={ref} mih={"1px"} miw={"1px"} {...rest}>
            {debouncedIsFetchingNextPage && <Loader m="auto" type="dots" />}
            {!hasNextPage && hasPreviousPage && (
                <Text c="dimmed" ta="center" pb="sm">
                    End of Results
                </Text>
            )}
        </Box>
    );
};
