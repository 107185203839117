import { Button, Divider, Select, Stack, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { senderQueryOptions } from "shared/graphql/senders";
import { SenderLikeFragment } from "src/gql/graphql";

export type SenderFormState = {
    name: string;
    fromEmail: string;
    fromPhone: string;
};

export const SenderForm = ({
    initialSender,
    handleSubmit,
}: {
    initialSender?: SenderLikeFragment;
    handleSubmit: (data: SenderFormState) => void;
}) => {
    const {
        data: { getAvailablePhoneNumbers: availablePhoneNumbers },
    } = useSuspenseQuery(senderQueryOptions.phoneNumbers);
    const {
        data: { getAvailableEmails: availableEmails },
    } = useSuspenseQuery(senderQueryOptions.emails);

    const form = useForm({
        mode: "uncontrolled",
        initialValues: {
            name: initialSender?.name ?? "",
            fromEmail: initialSender?.fromEmail ?? "",
            fromPhone: initialSender?.fromPhone ?? "",
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            fromEmail: (v) => (v.length == 0 ? "Invalid email" : null),
            fromPhone: (v) => (v.length == 0 ? "Invalid phone number" : null),
        },
    });

    return (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
                <TextInput label="Name" withAsterisk placeholder="Enter name..." {...form.getInputProps("name")} />
                <Select label="Email" data={availableEmails.map((res) => res.email)} {...form.getInputProps("fromEmail")} />
                <Select label="Phone Number" data={availablePhoneNumbers} {...form.getInputProps("fromPhone")} />
                <Divider />
                <Button type="submit" w="100%" disabled={!form.isDirty()}>
                    Save
                </Button>
            </Stack>
        </form>
    );
};
