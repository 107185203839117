import { Card, Textarea } from "@mantine/core";
import { useComposeFormSmsPreview } from "shared/components/message/compose/useComposeFormSmsPreview";

export const SmsPreview = () => {
    const { smsPreview } = useComposeFormSmsPreview();

    return (
        <Card shadow="sm" padding={"sm"} withBorder style={{ height: "fit-content", overflowY: "scroll" }}>
            <Textarea autosize variant="unstyled" value={smsPreview} />
        </Card>
    );
};
