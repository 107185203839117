import { titlecase } from "shared/utils/fns";
import { Cm } from "src/gql/graphql";
import { match, P } from "ts-pattern";

export function formatEud(opts: { type: Cm; name?: string; value: string }): string {
    return match(opts)
        .with({ type: Cm.Ios, name: P.string }, ({ name }) => `iOS Push Notifications (${name})`)
        .with({ type: Cm.Ios }, () => `iOS Push Notifications`)
        .with({ type: Cm.Android, name: P.string }, ({ name }) => `Android Push Notifications (${name})`)
        .with({ type: Cm.Android }, () => `iOS Push Notifications`)
        .with({ type: P.union(Cm.Sms, Cm.Phone) }, ({ type, value }) => `${titlecase(type)} ${value}`)
        .with({ name: P.string }, ({ type, name }) => `${titlecase(type)} (${name})`)
        .otherwise(({ type, value }) => titlecase(type) + " - " + value);
}
