import { Group, Stack, TypographyStylesProvider } from "@mantine/core";
import { useDebouncedCallback } from "@mantine/hooks";
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    headingsPlugin,
    InsertThematicBreak,
    linkDialogPlugin,
    linkPlugin,
    listsPlugin,
    ListsToggle,
    MDXEditor,
    thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo,
} from "@mdxeditor/editor";
import { useEffect } from "react";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useColorMode } from "shared/hooks/useColorMode";
import { useFormState } from "shared/hooks/useFormState";
import { mdToTxt } from "shared/utils/md";

export const MessageBodyEditor = () => {
    const form = useComposeFormContext();
    const [richbody, setRichbody] = useFormState(form, "richBody");
    const [, setBody] = useFormState(form, "body");

    const handleRichBodyChange = useDebouncedCallback(
        (richbody: string) => {
            void mdToTxt(richbody).then(setBody);
        },
        { delay: 100 },
    );

    useEffect(() => {
        handleRichBodyChange(richbody);
    }, [richbody]);

    const { isDark } = useColorMode();

    return (
        <Stack>
            <TypographyStylesProvider>
                <MDXEditor
                    placeholder="Let people know whats going on..."
                    className={isDark ? "dark-theme" : ""}
                    plugins={[
                        toolbarPlugin({
                            toolbarContents: () => (
                                <Group>
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <BlockTypeSelect />
                                    <CreateLink />
                                    <InsertThematicBreak />
                                    <ListsToggle />
                                </Group>
                            ),
                        }),
                        headingsPlugin({ allowedHeadingLevels: [1, 2, 3, 4, 5, 6] }),
                        linkPlugin(),
                        linkDialogPlugin(),
                        thematicBreakPlugin(),
                        listsPlugin(),
                    ]}
                    markdown={richbody}
                    onChange={(md) => setRichbody(md)}
                />
            </TypographyStylesProvider>
        </Stack>
    );
};
