import {
    Box,
    BoxComponentProps,
    Container,
    ContainerProps,
    Group,
    GroupProps,
    MantineStyleProps,
    Paper,
    PolymorphicComponentProps,
    Stack,
    StackProps,
} from "@mantine/core";
import { ReactNode } from "react";
import { BackButton } from "shared/components/global/BackButton";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { some } from "shared/utils/maybe";
import { createMarkerComponent, createSlots } from "shared/utils/slots";

type ContentProps = {
    /**
     * Additonal props to pass to the underlying {@link Stack} wrapper
     */
    stack?: StackProps;
    paper?: boolean;
} & PolymorphicComponentProps<"div", BoxComponentProps>;

/** @private */
const Heading = ({ backable, children, ...rest }: { backable?: boolean } & GroupProps) => (
    <Group py="md" {...rest}>
        {backable && <BackButton />}
        {children}
    </Group>
);

const Action = createMarkerComponent();

/**
 * A responsive {@link Container} wrapper. This is the default wrapper that should be used for all pages.
 */
export const Content = Object.assign(
    ({ stack, paper, children, ...rest }: ContentProps) => {
        const Wrapper = paper ? PaperWrapper : Box;
        const layoutProps = paper ? {} : rest;

        const slots = createSlots(children, {
            Heading,
            Action,
        });

        return (
            <Box>
                <Layout {...layoutProps}>
                    <Group w="100%" justify="space-between">
                        {slots.Heading}
                        {some(slots.Action)
                            .if((it) => !!it)
                            ?.take((it) => <Group gap="xs">{it}</Group>)}
                    </Group>
                    <Wrapper {...rest}>
                        <Stack {...stack}>{slots.rest}</Stack>
                    </Wrapper>
                </Layout>
            </Box>
        );
    },
    {
        /**
         * Children to be rendered within the Layout, but above and outside the Wrapper
         */
        Heading,
        Action,
    },
);

const Layout = ({ children, ...rest }: ContainerProps) => {
    const isMobile = useIsMobile();

    return (
        <Container size={"lg"} px={isMobile ? "sm" : undefined} {...rest}>
            {children}
        </Container>
    );
};

const PaperWrapper = ({ children, ...styles }: { children: ReactNode } & MantineStyleProps) => {
    const isMobile = useIsMobile();
    return (
        <Paper p="sm" mb="lg" radius={isMobile ? 0 : "md"} {...styles}>
            {children}
        </Paper>
    );
};
