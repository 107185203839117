import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { WebStorageStateStore } from "oidc-client-ts";
import { AuthContextProps, useAuth } from "react-oidc-context";
import { httpPostGraphql } from "shared/api/httpClient";
import { useIsSupport } from "shared/hooks/useIsSupport";
import { fail } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Permission, PermissionLikeFragment } from "src/gql/graphql";
import { z } from "zod";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type OidcStore = {
    config: {
        authority: string;
        client_id: string;
        redirect_uri: string;
        post_logout_redirect_uri: string;
        scope: string;
        userStore: WebStorageStateStore;
    };
    setRealm: (realm: string) => void;
    getAccessToken: () => string | undefined;
};

const [subdomain] = window.location.hostname.split(".");
const realm = ["localhost", "emns.pages.dev"].some((s) => window.location.origin.includes(s))
    ? import.meta.env["VITE_FALLBACK_REALM"]
    : (subdomain ?? import.meta.env["VITE_FALLBACK_REALM"]);

const userStoreDefault = {
    config: {
        authority: `${import.meta.env["VITE_KC_BASE_URL"]}/realms/${realm}`,
        client_id: "emns-web",
        redirect_uri: `${window.location.origin}/account/callback`,
        post_logout_redirect_uri: `${window.location.origin}/login`,
        scope: "openid profile email",
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    },
};

logger.log("Using OIDC config", userStoreDefault.config);

export const useOIDCStore = create<OidcStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set, get) => {
                return {
                    ...userStoreDefault,
                    setRealm: (realm: string) => {
                        set((state) => {
                            state.config.authority = `${import.meta.env.VITE_KC_BASE_URL}/realms/${realm}`;
                        });
                    },
                    getAccessToken() {
                        const {
                            config: { authority, client_id },
                        } = get();
                        const raw = localStorage.getItem(`oidc.user:${authority}:${client_id}`);
                        if (raw == null) return undefined;

                        const parsed = z.object({ access_token: z.string() }).safeParse(JSON.parse(raw));
                        if (parsed.success) return parsed.data.access_token;
                        else return undefined;
                    },
                };
            }),
            {
                name: "emns.store.oidc",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    config: store.config,
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Auth" },
    ),
);

export function getUserId(auth: AuthContextProps): string {
    return import.meta.env["VITE_USER_ID_OVERRIDE"] ?? auth.user?.profile.sub ?? fail("auth context is unset");
}

export function useUserId() {
    const auth = useAuth();
    const { isSupport } = useIsSupport();
    return { userId: getUserId(auth), isSupport };
}

export function usePermissions() {
    const {
        data: { clientPermissions },
    } = useSuspenseQuery(oidcQueryOptions.clientPermissions);
    return { permissions: clientPermissions as PermissionLikeFragment };
}

export function permissionCheck(userPerms: PermissionLikeFragment, neededPerm: Permission, withId?: string): boolean {
    return (
        !!userPerms.customer.find((p) => p == neededPerm) ||
        !!userPerms.group.find((g) => (withId ? g.id == withId : true) && g.permissions.some((p) => p == neededPerm)) ||
        !!userPerms.topic.find((t) => (withId ? t.id == withId : true) && t.permissions.some((p) => p == neededPerm))
    );
}

export function usePermissionCheck(neededPerm: Permission, withId?: string) {
    const { permissions } = usePermissions();
    return permissionCheck(permissions, neededPerm, withId);
}

/** @public */
export function permissionsCheck(userPerms: PermissionLikeFragment, opts: [permission: Permission, withId?: string][]): boolean {
    return opts.reduce((isPassing, args) => isPassing && permissionCheck(userPerms, ...args), true);
}

/** @public */
export function usePermissionsCheck(opts: [permission: Permission, withId?: string][]) {
    const { permissions } = usePermissions();
    return opts.reduce((isPassing, args) => isPassing && permissionCheck(permissions, ...args), true);
}

export type PermissionBuilder =
    | {
          type: "permission";
          permission: Permission;
          withId?: string;
      }
    | {
          type: "builder";
          left: PermissionBuilder;
          right: PermissionBuilder;
          op?: "and" | "or"; // and is default
          withId?: string;
      };

export function parsePermissions(userPerms: PermissionLikeFragment, permission: PermissionBuilder): boolean {
    if (permission.type == "permission") return permissionCheck(userPerms, permission.permission, permission.withId);

    if (permission.op == "or") return parsePermissions(userPerms, permission.left) || parsePermissions(userPerms, permission.right);
    else return parsePermissions(userPerms, permission.left) && parsePermissions(userPerms, permission.right);
}

const oidcQueryOptions = {
    clientPermissions: queryOptions({
        queryKey: qk("user", "permissions"),
        queryFn: () => httpPostGraphql(queries.clientPermissions, {}),
    }),
};

const queries = {
    permissionsFragment: graphql(`
        fragment PermissionLike on ClientPermissionNode {
            customer
            group {
                id
                permissions
            }
            topic {
                id
                permissions
            }
        }
    `),
    clientPermissions: graphql(`
        query Permissions {
            clientPermissions {
                ...PermissionLike
            }
        }
    `),
};
