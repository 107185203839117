import { queryOptions, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { permissionCheck, usePermissions, useUserId } from "shared/stores/oidc";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { GroupLikeFragment, Permission, PermissionType } from "src/gql/graphql";
import { match } from "ts-pattern";

const userQuery = graphql(`
    query User($id: UUID!) {
        user(userId: $id) {
            id
            name
            language
            topics {
                id
                name
            }
            groups {
                ...GroupLike
            }
            topics {
                ...TopicLike
            }
            clientPermissions {
                ...PermissionLike
            }
            euds {
                id
                name
                type
                value
                isVerified
                isDefault
            }
            addresses {
                name
                city
                id
                state
                street
                street2
                zip
            }
        }
    }
`);

/** @public */
export function useUser(id?: string) {
    const { userId, isSupport } = useUserId();
    const { data } = useSuspenseQuery({ ...userQueryOptions({ userId, isSupport }) });
    if (isSupport && !id) return { user: undefined };
    return { user: data?.user };
}

// TODO: implement UI for support users to view other users's groups
/** @public */
export function useUserGroups(id?: string) {
    const { userId, isSupport } = useUserId();
    if (isSupport && !id) return { groups: [] };
    const { data: groups } = useSuspenseQuery({
        ...userQueryOptions({ userId: id ?? userId, isSupport }),
        select: (data) => data?.user.groups ?? [],
    });
    return { groups: groups as GroupLikeFragment[] };
}

/** @public */
export function useUserEuds(id?: string) {
    const { userId, isSupport } = useUserId();
    if (isSupport && !id) return { euds: [] };
    const { data: euds } = useSuspenseQuery({
        ...userQueryOptions({ userId: id ?? userId, isSupport }),
        select: (data) => data?.user.euds ?? [],
    });
    return { euds };
}

/** @public */
export function useUserRoles() {
    const { permissions } = usePermissions();
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(infiniteRolesQueryOptions);
    const unfilteredRoles = pages.flat();

    const canModifyTopicRoles = permissionCheck(permissions, Permission.TopicRolesManage);
    const canModifyGroupRoles = permissionCheck(permissions, Permission.GroupRolesManage);
    const canModifyCustomerRoles = permissionCheck(permissions, Permission.CustomerRolesManage);
    const roles = unfilteredRoles.filter((role) =>
        match(role.type)
            .with(PermissionType.Customer, () => canModifyCustomerRoles)
            .with(PermissionType.Group, () => canModifyGroupRoles)
            .with(PermissionType.Topic, () => canModifyTopicRoles)
            .exhaustive(),
    );

    return { roles };
}

/** @public */
export function userQueryOptions({ userId, isSupport }: { userId: string; isSupport: boolean }) {
    return queryOptions({
        queryKey: qk("user", "id", userId),
        staleTime: 5 * 60 * 60, // 5 minutes
        queryFn: async () => {
            if (isSupport) return null;
            return httpPostGraphql(userQuery, { id: userId });
        },
    });
}

const infiniteUserQuery = graphql(`
    query Users($limit: Int!, $offset: Int!, $nameQuery: String, $groupId: UUID, $roleId: UUID) {
        users(limit: $limit, offset: $offset, nameQuery: $nameQuery, groupId: $groupId, roleId: $roleId) {
            id
            name
            euds {
                type
                value
            }
            roles {
                id
                role {
                    id
                    name
                    type
                }
                group {
                    id
                }
                topic {
                    id
                }
            }
            groups {
                name
            }
            lastLogin
            loginEmail
        }
    }
`);

/** @public */
export const infiniteRolesQueryOptions = createOffsetBasedGqlQueryOptions({
    queryKey: qk("roles", "list"),
    select: ({ roles }) => roles,
    document: graphql(`
        query OrganizationRoles($limit: Int!, $offset: Int!) {
            roles(limit: $limit, offset: $offset) {
                id
                name
                type
            }
        }
    `),
});

/** @public */
export const infiniteUserQueryOptions = (props?: { nameQuery?: string; groupId?: string; roleId?: string }) =>
    createOffsetBasedGqlQueryOptions({
        queryKey: qk(
            "user",
            "search",
            "nameQuery",
            props?.nameQuery ?? "unset",
            "groupId",
            props?.groupId ?? "unset",
            "roleId",
            props?.roleId ?? "unset",
        ),
        variables: {
            nameQuery: props?.nameQuery,
            groupId: props?.groupId,
            roleId: props?.roleId,
        },
        limit: 20,
        select: ({ users }) =>
            users.map((user) => ({
                userId: user.id,
                contactInfo: { name: user.name ?? "", loginEmail: user.loginEmail },
                roles: user.roles,
                groups: user.groups,
                lastActive: user.lastLogin,
            })),
        document: infiniteUserQuery,
    });

// NOTE: QueryKeys are not handled here since these operations are intended to be used
// many times in a bulk operation. When we get real bulk mutation endpoints the query keys
// should be moved here
/** @public */
export function useAssignGroupRole() {
    const { mutateAsync: assignGroupRoleAsync } = useGraphqlMutation(mutations.assignGroupRole);
    return { assignGroupRoleAsync };
}

/** @public */
export function useAssignTopicRole() {
    const { mutateAsync: assignTopicRoleAsync } = useGraphqlMutation(mutations.assignTopicRole);
    return { assignTopicRoleAsync };
}

/** @public */
export function useAssignCustomerRole() {
    const { mutateAsync: assignCustomerRoleAsync } = useGraphqlMutation(mutations.assignCustomerRole);
    return { assignCustomerRoleAsync };
}

/** @public */
export function useUnassignRole() {
    const { mutateAsync: unassignRoleAsync } = useGraphqlMutation({
        document: mutations.unassignRole,
        onSuccess: async () => {
            await qk.invalidate("user");
        },
    });
    return { unassignRoleAsync };
}

/** @public */
export function useDeleteUser() {
    const { mutateAsync: deleteUserAsync } = useGraphqlMutation({
        document: mutations.deleteUser,
    });
    return { deleteUserAsync };
}

/** @public */
export function useSetEudInformational(onSuccess?: () => void) {
    const { mutate: setEudInformational } = useGraphqlMutation({
        document: mutations.setEudInformational,
        onSuccess,
    });
    return { setEudInformational };
}

const mutations = {
    deleteUser: graphql(`
        mutation DeleteUser($userId: UUID!) {
            archiveUser(userId: $userId)
        }
    `),
    assignCustomerRole: graphql(`
        mutation AssignCustomerRole($userId: UUID!, $roleId: UUID!) {
            assignCustomerRole(params: { roleId: $roleId, userId: $userId }) {
                id
            }
        }
    `),
    assignGroupRole: graphql(`
        mutation AssignGroupRole($userId: UUID!, $roleId: UUID!, $groupId: UUID!) {
            assignGroupRole(params: { roleId: $roleId, userId: $userId, groupId: $groupId }) {
                id
            }
        }
    `),
    assignTopicRole: graphql(`
        mutation AssignTopicRole($userId: UUID!, $roleId: UUID!, $topicId: UUID!) {
            assignTopicRole(params: { roleId: $roleId, userId: $userId, topicId: $topicId }) {
                id
            }
        }
    `),
    unassignRole: graphql(`
        mutation UnassignRole($userId: UUID!, $userRoleId: UUID!) {
            unassignRole(userId: $userId, userRoleId: $userRoleId)
        }
    `),
    setEudInformational: graphql(`
        mutation RegisterMarkEudAsInformational($userId: UUID!, $eudId: UUID!, $isInformational: Boolean!) {
            setDefaultEud(userId: $userId, eudId: $eudId, isDefault: $isInformational) {
                id
            }
        }
    `),
};
