import { Badge, Button, Divider, Group, Stack, Text, Tooltip } from "@mantine/core";
import { IconSend } from "@tabler/icons-react";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { ReactNode } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Section, SectionProvider } from "shared/components/global/Section";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

type SendPanelProps = {
    msgId: string;
    onClose?: () => unknown;
    onCancel?: () => unknown;
    onSend?: (conf: { expireAt: string | null }) => unknown;
};

export const SendPanel = ({ msgId, onClose, onCancel, onSend }: SendPanelProps) => {
    const { data } = useSuspenseQuery(query({ msgId }));

    return (
        <Stack>
            <Text>You are about to send a message. Are you sure you want to continue?</Text>
            <SectionProvider>
                <Section label={"Recipients"}>
                    <Group gap={"xs"}>
                        <PropertyBadge title="Users Notified">{data.uniqueUserCount}</PropertyBadge>
                        <PropertyBadge title="Total Deliveries">{data.totalDeliveryCount}</PropertyBadge>
                        {data.totalEmails && <PropertyBadge title="Emails">{data.totalEmails}</PropertyBadge>}
                        {!!data.totalTexts && <PropertyBadge title="Texts (SMS)">{data.totalTexts}</PropertyBadge>}
                        {!!data.totalPhoneCalls && <PropertyBadge title="Phone Calls">{data.totalPhoneCalls}</PropertyBadge>}
                        {!!(data.totalAndroid + data.totalIOS) && (
                            <PropertyBadge title="Mobile Push">{data.totalAndroid + data.totalIOS}</PropertyBadge>
                        )}
                    </Group>
                </Section>
            </SectionProvider>
            <Divider />
            <Group justify="flex-end">
                <Button
                    variant="default"
                    onClick={() => {
                        onCancel?.();
                        onClose?.();
                    }}
                >
                    Cancel
                </Button>
                <Tooltip label="Message send requires at least one recipient" disabled={data.totalDeliveryCount != 0}>
                    <Button
                        disabled={data.totalDeliveryCount == 0}
                        leftSection={<IconSend size={16} />}
                        onClick={() => {
                            onSend?.({ expireAt: null });
                            onClose?.();
                        }}
                    >
                        Send
                    </Button>
                </Tooltip>
            </Group>
        </Stack>
    );
};

const query = ({ msgId }: { msgId: string }) =>
    queryOptions({
        staleTime: 0, // don't cache
        queryKey: qk("compose", "projected"),
        queryFn: () =>
            httpPostGraphql(
                graphql(`
                    query SendPanelProjectedDelivery($msgId: UUID!) {
                        message(id: $msgId) {
                            projectedDelivery {
                                uniqueUserCount
                                totalEmails: deliveryCount(cm: EMAIL)
                                totalPhoneCalls: deliveryCount(cm: PHONE)
                                totalTexts: deliveryCount(cm: SMS)
                                totalAndroid: deliveryCount(cm: ANDROID)
                                totalIOS: deliveryCount(cm: IOS)
                                totalDeliveryCount
                            }
                        }
                    }
                `),
                { msgId },
            ),
        select: ({ message }) => message.projectedDelivery,
    });

const PropertyBadge = (props: { title: string; children: ReactNode }) => (
    <Group gap={0} wrap="nowrap">
        <Badge
            variant="default"
            style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                marginRight: "-1px",
            }}
        >
            {props.title}
        </Badge>
        <Badge
            variant="dot"
            color={"transparent"}
            styles={{ root: { paddingLeft: 0 } }}
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
            <Text size="xs" fw="bold">
                {props.children}
            </Text>
        </Badge>
    </Group>
);
