// Query key invalidation occurs in src/routes/_auth/organization/groups/$groupId.tsx

import { queryOptions } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useUserId } from "shared/stores/oidc";
import { to } from "shared/utils/fns";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { graphql } from "src/gql";
import { GroupLikeFragment, SenderLikeFragment } from "src/gql/graphql";

/** @public */
export function useCreateGroup() {
    const { mutate: createGroup, mutateAsync: createGroupAsync } = useGraphqlMutation({
        document: mutations.createGroup,
        onSuccess: async () => {
            await Promise.all([qk.invalidate("user"), qk.invalidate("group")]);
            notify.show.success({ message: "Created new group" });
        },
    });
    return { createGroup, createGroupAsync };
}

// for the following three mutations
/** @public */
export function useUpdateGroup() {
    const { mutateAsync: updateGroupAsync } = useGraphqlMutation(mutations.updateGroup);
    return { updateGroupAsync };
}

/** @public */
export function useAddParentToGroup() {
    const { mutateAsync: addParentAsync } = useGraphqlMutation(mutations.addParent);
    return { addParentAsync };
}

/** @public */
export function useRemoveParentFromGroup() {
    const { mutateAsync: removeParentAsync } = useGraphqlMutation(mutations.removeParent);
    return { removeParentAsync };
}

/** @public */
export function useAddUserToGroup() {
    const { userId } = useUserId();
    const { mutateAsync: addUserAsync } = useGraphqlMutation({
        document: mutations.addUserToGroup,
        onSuccess: async (data) => {
            if (!!data?.addUserToGroup) {
                const group = data.addUserToGroup as GroupLikeFragment;
                await Promise.all([qk.invalidate("user", "id", userId), qk.invalidate("group", "id", group.id, "members")]);
            }
        },
    });
    return { addUserAsync };
}

/** @public */
export function useAddUsersToGroup() {
    const { userId } = useUserId();
    const { mutate: addUsers } = useGraphqlMutation({
        document: mutations.addUsersToGroup,
        onSuccess: async (data) => {
            if (!!data?.addUsersToGroup) {
                const group = data.addUsersToGroup as GroupLikeFragment;
                await Promise.all([qk.invalidate("user", "id", userId), qk.invalidate("group", "id", group.id, "members")]);
            }
        },
    });
    return { addUsers };
}

/** @public */
export function useRemoveUserFromGroup() {
    const { userId } = useUserId();
    const { mutate: removeUser, mutateAsync: removeUserAsync } = useGraphqlMutation({
        document: mutations.removeUserFromGroup,
        onSuccess: async (data) => {
            const groupId = data?.removeUserFromGroup.id;
            await Promise.all([qk.invalidate("user", "id", userId), !!groupId && qk.invalidate("group", "id", groupId, "members")]);
        },
    });
    return { removeUser, removeUserAsync };
}

const mutations = {
    createGroup: graphql(`
        mutation CreateGroup($internalJoinable: Boolean!, $name: String!, $parentGroupIds: [UUID!]!, $members: [UUID!]!) {
            createGroup(params: { internalJoinable: $internalJoinable, name: $name, parentGroupIds: $parentGroupIds, members: $members }) {
                id
                parents {
                    id
                }
            }
        }
    `),
    addUsersToGroup: graphql(`
        mutation AddUsersToGroup($groupId: UUID!, $userIds: [UUID!]!) {
            addUsersToGroup(groupId: $groupId, userIds: $userIds) {
                id
            }
        }
    `),
    removeMembersFromGroup: graphql(`
        mutation RemoveGroupMember($userId: UUID!, $groupId: UUID!) {
            removeUserFromGroup(userId: $userId, groupId: $groupId) {
                id
            }
        }
    `),
    updateGroup: graphql(`
        mutation UpdateGroup($groupId: UUID!, $internalJoinable: Boolean!, $name: String!) {
            updateGroup(id: $groupId, params: { internalJoinable: $internalJoinable, name: $name }) {
                id
            }
        }
    `),
    addParent: graphql(`
        mutation AddParentGrouop($groupId: UUID!, $parentId: UUID!) {
            addParentToGroup(groupId: $groupId, parentId: $parentId) {
                id
            }
        }
    `),
    removeParent: graphql(`
        mutation RemoveParentGroup($groupId: UUID!, $parentId: UUID!) {
            removeParentFromGroup(groupId: $groupId, parentId: $parentId) {
                id
            }
        }
    `),
    addUserToGroup: graphql(`
        mutation AddUserToGroup($groupId: UUID!, $userId: UUID!) {
            addUserToGroup(groupId: $groupId, userId: $userId) {
                ...GroupLike
            }
        }
    `),
    removeUserFromGroup: graphql(`
        mutation RemoveUserFromGroup($groupId: UUID!, $userId: UUID!) {
            removeUserFromGroup(groupId: $groupId, userId: $userId) {
                id
            }
        }
    `),
};

export function infiniteMembersQueryOptions(id: string) {
    return createOffsetBasedGqlQueryOptions({
        queryKey: qk("group", "id", id, "members"),
        variables: {
            id,
        },
        limit: 5,
        document: groupsGql.groupMembersQuery,
        select: (data) => data.group.membersPaged,
    });
}

/** @public */
export const groupsQueryOptions = {
    rootGroup: queryOptions({
        queryKey: qk("group", "rootGroup"),
        queryFn: () => httpPostGraphql(groupsGql.rootGroupQuery, {}),
        select: ({ rootGroup }) => ({ rootGroup: rootGroup as GroupLikeFragment }),
    }),
    groupDetails: (id: string) =>
        queryOptions({
            queryKey: qk("group", "id", id, "details"),
            queryFn: () => httpPostGraphql(groupsGql.groupQuery, { id }),
            select: ({ group }) => ({ group: group as GroupLikeFragment }),
        }),
    groupSenders: (id: string) =>
        queryOptions({
            queryKey: qk("group", "id", id, "senders"),
            queryFn: () => httpPostGraphql(groupsGql.groupSendersQuery, { id }),
            select: (data) => ({ senders: data.group.senders as SenderLikeFragment[] }),
        }),
    children: (id: string) =>
        queryOptions({
            queryKey: qk("group", "id", id, "children"),
            queryFn: () => httpPostGraphql(groupsGql.groupChildrenQuery, { id }),
        }),
    search: (search: string) =>
        queryOptions({
            queryKey: qk("messages", "composer", "groups", "search", search),
            queryFn: () => httpPostGraphql(groupsGql.groupSearchQuery, { search }),
            select: to("searchGroups"),
        }),
};

const groupsGql = {
    groupFragment: graphql(`
        fragment GroupLike on GroupNode {
            id
            name
            internalJoinable
            synchronized
            parents {
                id
                name
            }
        }
    `),
    membersFragment: graphql(`
        fragment MemberLike on UserNode {
            id
            name
            euds {
                type
                value
            }
            loginEmail
        }
    `),
    senderFragment: graphql(`
        fragment SenderLike on SenderNode {
            id
            name
            fromEmail
            fromPhone
            verificationSender
        }
    `),
    rootGroupQuery: graphql(`
        query RootGroup {
            rootGroup {
                ...GroupLike
            }
        }
    `),
    groupQuery: graphql(`
        query Group($id: UUID!) {
            group(id: $id) {
                ...GroupLike
            }
        }
    `),
    groupSendersQuery: graphql(`
        query GroupSenders($id: UUID!) {
            group(id: $id) {
                senders {
                    ...SenderLike
                }
            }
        }
    `),
    groupChildrenQuery: graphql(`
        query GroupChildren($id: UUID!) {
            group(id: $id) {
                children {
                    ...GroupLike
                }
            }
        }
    `),
    groupMembersQuery: graphql(`
        query GroupMembers($id: UUID!, $offset: Int!, $limit: Int!) {
            group(id: $id) {
                membersPaged(offset: $offset, limit: $limit) {
                    ...MemberLike
                }
            }
        }
    `),
    groupSearchQuery: graphql(`
        query GroupSearch($search: String!) {
            searchGroups(limit: 10, offset: 0, permissions: [], query: $search) {
                ...GroupLike
            }
        }
    `),
};
