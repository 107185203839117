import { Badge, Group, Skeleton, Stack, Table, Text } from "@mantine/core";
import { range } from "@mantine/hooks";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { catching } from "shared/utils/fns";
import { maybe } from "shared/utils/maybe";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { theme } from "shared/utils/theme";
import { graphql } from "src/gql";
import { Cm, Status } from "src/gql/graphql";
import { match } from "ts-pattern";
import { z } from "zod";

const query = {
    metrics: (msgId: string, cm?: Cm, ackable?: boolean) =>
        createOffsetBasedGqlQueryOptions({
            queryKey: qk("messages", "insights", "metrics", cm ?? "ALL", msgId),
            variables: { id: msgId, cm, ackable: !!ackable },
            document: graphql(`
                query InsightsTableDataMetrics($id: UUID!, $cm: CM, $ackable: Boolean!, $limit: Int!, $offset: Int!) {
                    message(id: $id) {
                        insights(cm: $cm) {
                            metrics(limit: $limit, offset: $offset) {
                                id
                                acknowledged @include(if: $ackable)
                                cm
                                device
                                sentAt
                                status
                                user
                            }
                        }
                    }
                }
            `),
            select: ({ message }) => message.insights.metrics,
        }),
};

type InsightsTableProps = {
    msgId: string;
    cm?: Cm;
    ackable?: boolean;
};

export const InsightsTable = Object.assign(
    (props: InsightsTableProps) => {
        const {
            data: { pages },
            isFetching,
        } = useSuspenseInfiniteQuery(query.metrics(props.msgId, props.cm, props.ackable));
        const metrics = pages.flat();

        return (
            <Stack>
                <Table.ScrollContainer minWidth={theme.breakpoints.sm}>
                    <Table striped>
                        <Table.Thead>
                            <Table.Tr>
                                {isFetching && metrics.length == 0 ? (
                                    <SkeletonRow />
                                ) : (
                                    <>
                                        <Table.Th>Status</Table.Th>
                                        <Table.Th>Channel</Table.Th>
                                        <Table.Th>Sent At</Table.Th>
                                        <Table.Th>User</Table.Th>
                                        <Table.Th>Device</Table.Th>
                                        {props.ackable && <Table.Th>Acknowledged</Table.Th>}
                                    </>
                                )}
                            </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                            {isFetching && metrics.length == 0 && range(0, 5).map((i) => <SkeletonRow key={i} />)}
                            {metrics.map((metric, i) => (
                                <Table.Tr key={i}>
                                    <Table.Td>
                                        {match(metric.status)
                                            .with(Status.Ok, () => <Badge color="green">Ok</Badge>)
                                            .with(Status.Fail, () => <Badge color="red">Fail</Badge>)
                                            .otherwise(() => (
                                                <Badge color="gray">Pending</Badge>
                                            ))}
                                    </Table.Td>
                                    <Table.Td>{metric.cm}</Table.Td>
                                    <Table.Td>
                                        {maybe(metric.sentAt)
                                            ?.map((it) => catching(() => z.coerce.number().pipe(z.coerce.date()).parse(it)))
                                            ?.take((it) => (
                                                <Text size="sm">
                                                    {it.toLocaleString(undefined, {
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        hour: "numeric",
                                                        minute: "2-digit",
                                                        second: "2-digit",
                                                        timeZoneName: "short",
                                                    })}
                                                </Text>
                                            )) ?? <span>&mdash;</span>}
                                    </Table.Td>
                                    <Table.Td>{metric.user}</Table.Td>
                                    <Table.Td>{metric.device}</Table.Td>
                                    {maybe(metric.acknowledged)?.take((it) => (
                                        <Table.Td>
                                            {it ? (
                                                <Badge variant="light" color="green">
                                                    YES
                                                </Badge>
                                            ) : (
                                                <Badge variant="default">NO</Badge>
                                            )}
                                        </Table.Td>
                                    ))}
                                </Table.Tr>
                            ))}
                        </Table.Tbody>
                        <Table.Tfoot>
                            {metrics.length == 0 && !isFetching && (
                                <tr>
                                    <td colSpan={5}>
                                        <Group justify="center" m="md">
                                            <Text>No Results</Text>
                                        </Group>
                                    </td>
                                </tr>
                            )}
                        </Table.Tfoot>
                        <LoadMoreTrigger query={query.metrics(props.msgId, props.cm, props.ackable)} />
                    </Table>
                </Table.ScrollContainer>
            </Stack>
        );
    },
    { query },
);

const SkeletonRow = () => (
    <Table.Tr>
        <Table.Td>
            <Skeleton w="50px" h="20px" />
        </Table.Td>

        <Table.Td>
            <Skeleton w="50px" h="20px" />
        </Table.Td>

        <Table.Td>
            <Skeleton w="100%" h="20px" />
        </Table.Td>

        <Table.Td>
            <Skeleton w="50px" h="20px" />
        </Table.Td>

        <Table.Td>
            <Skeleton w="50px" h="20px" />
        </Table.Td>
    </Table.Tr>
);
