import { Button, Group, Stack, Switch, Text, Textarea } from "@mantine/core";
import { IconFileDownload } from "@tabler/icons-react";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormState } from "shared/hooks/useFormState";

export const CustomizationEditor = () => {
    const form = useComposeFormContext();

    const [smsEnabled, setSmsEnabled] = useFormState(form, "customizations.sms.enabled");
    const [smsBody, setSmsBody] = useFormState(form, "customizations.sms.value");
    const [phoneEnabled, setPhoneEnabled] = useFormState(form, "customizations.phone.enabled");
    const [phoneBody, setPhoneBody] = useFormState(form, "customizations.phone.value");

    return (
        <Stack>
            <Customization
                label="Customize Voice (Text to Speech)"
                value={phoneBody}
                onChange={setPhoneBody}
                enabled={phoneEnabled}
                setEnabled={setPhoneEnabled}
            />

            <Customization label="Customize SMS" value={smsBody} onChange={setSmsBody} enabled={smsEnabled} setEnabled={setSmsEnabled} />
        </Stack>
    );
};

const Customization = (props: {
    label: string;
    enabled: boolean;
    setEnabled?: (enabled: boolean) => unknown;
    value: string;
    onChange?: (value: string) => unknown;
}) => {
    const form = useComposeFormContext();
    const [body] = useFormState(form, "body");

    return (
        <Stack>
            <Group>
                <Switch checked={props.enabled} onChange={(e) => props.setEnabled?.(e.target.checked)} />
                <Text>{props.label}</Text>
                {props.enabled && (
                    <Button variant="transparent" leftSection={<IconFileDownload size={16} />} onClick={() => props.onChange?.(body)}>
                        Use Body
                    </Button>
                )}
            </Group>
            {props.enabled && (
                <Textarea
                    autosize
                    resize="vertical"
                    placeholder="Edit text"
                    value={props.value}
                    onChange={(e) => props.onChange?.(e.target.value)}
                />
            )}
        </Stack>
    );
};
