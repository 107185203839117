import { queryOptions } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

/** @public */
export function useUpdateSender() {
    const { mutate: updateSender } = useGraphqlMutation({
        document: mutations.updateSender,
        onSuccess: async (data) => {
            const sender = data!.updateSender;
            await Promise.all([qk.invalidate("senders", "id", sender.id), qk.invalidate("group", "id", sender.group.id, "senders")]);
            notify.show.success({ message: "Sender updated" });
        },
    });
    return { updateSender };
}

/** @public */
export function useCreateSender() {
    const { mutate: createSender } = useGraphqlMutation({
        document: mutations.createSender,
        onSuccess: async (data) => {
            const sender = data!.createSender;
            await Promise.all([qk.invalidate("senders", "list"), qk.invalidate("group", "id", sender.group.id, "senders")]);
            notify.show.success({ message: "Sender created" });
        },
    });
    return { createSender };
}

/** @public */
export function useDeleteSender(groupId: string) {
    const { mutate: deleteSender } = useGraphqlMutation({
        document: mutations.deleteSender,
        onSuccess: async () => {
            await Promise.all([qk.invalidate("senders", "list"), qk.invalidate("group", "id", groupId, "senders")]);
            notify.show.success({ message: "Sender deleted" });
        },
    });
    return { deleteSender };
}

/** @public */
export const senderQueryOptions = {
    phoneNumbers: queryOptions({ queryKey: qk("senders", "phoneNumbers"), queryFn: () => httpPostGraphql(queries.senderPhoneNumbers, {}) }),
    emails: queryOptions({ queryKey: qk("senders", "emails"), queryFn: () => httpPostGraphql(queries.senderEmails, {}) }),
};

const queries = {
    senderPhoneNumbers: graphql(`
        query SenderPhoneNumbers {
            getAvailablePhoneNumbers
        }
    `),
    senderEmails: graphql(`
        query SenderEmails {
            getAvailableEmails {
                email
            }
        }
    `),
};

const mutations = {
    createSender: graphql(`
        mutation createSender($name: String!, $groupId: UUID!, $fromEmail: String!, $fromPhone: String!) {
            createSender(params: { name: $name, group: $groupId, fromEmail: $fromEmail, fromPhone: $fromPhone }) {
                id
                group {
                    id
                }
            }
        }
    `),
    updateSender: graphql(`
        mutation updateSender($senderId: UUID!, $params: UpdateSenderParametersInput!) {
            updateSender(id: $senderId, params: $params) {
                id
                group {
                    id
                }
            }
        }
    `),
    deleteSender: graphql(`
        mutation deleteSender($senderId: UUID!) {
            deleteSender(id: $senderId)
        }
    `),
};
