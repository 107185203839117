import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormState } from "shared/hooks/useFormState";
import { some } from "shared/utils/maybe";

export function useComposeFormSmsPreview() {
    const form = useComposeFormContext();
    const [plainTextBody] = useFormState(form, "body");
    const [smsCustomizationValue] = useFormState(form, "customizations.sms.value");
    const [smsCustomizationEnabled] = useFormState(form, "customizations.sms.enabled");
    const [subject] = useFormState(form, "subject");

    const body = some(smsCustomizationValue).takeIf(() => smsCustomizationEnabled) ?? plainTextBody;
    const raw = [subject, body].join("\n");

    return { smsPreview: some(raw.slice(0, 1400)).take((it) => (it != raw ? it + "..." : it)) };
}
