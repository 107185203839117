import { IconChartPie2, IconInbox, IconSettings } from "@tabler/icons-react";
import { SidebarLinkData } from "shared/components/global/Sidebar/SidebarLink";
import { PermissionBuilder } from "shared/stores/oidc";
import { Permission } from "src/gql/graphql";

const roleAssignPermissionObject: PermissionBuilder = {
    type: "builder",
    op: "or",
    left: { type: "permission", permission: Permission.GroupRolesManage },
    right: {
        type: "builder",
        op: "or",
        left: { type: "permission", permission: Permission.TopicRolesManage },
        right: { type: "permission", permission: Permission.CustomerRolesManage },
    },
};

export const sidebarLinkData: SidebarLinkData[] = [
    {
        icon: IconInbox,
        label: "Messages",
        to: "/messages",
        index: 0,
        subLinks: {
            header: "Messages",
            data: [
                { label: "Inbox", to: "/messages/inbox" },
                {
                    label: "Drafts",
                    to: "/messages/drafts",
                    permissions: { type: "permission", permission: Permission.GroupMessageSendFrom },
                },
                {
                    label: "Scheduled",
                    to: "/messages/scheduled",
                    permissions: { type: "permission", permission: Permission.GroupMessageSchedule },
                },
                {
                    label: "Sent",
                    to: "/messages/sent",
                    permissions: { type: "permission", permission: Permission.GroupMessageSendFrom },
                },
                {
                    label: "Templates",
                    to: "/messages/templates",
                    permissions: {
                        type: "builder",
                        op: "or",
                        left: { type: "permission", permission: Permission.GroupTemplateCreate },
                        right: {
                            type: "builder",
                            op: "or",
                            left: { type: "permission", permission: Permission.GroupTemplateUpdate },
                            right: { type: "permission", permission: Permission.GroupTemplateDelete },
                        },
                    },
                },
            ],
        },
    },
    {
        icon: IconChartPie2,
        label: "Organization",
        to: "/organization",
        index: 1,
        permissions: {
            type: "builder",
            op: "or",
            left: { type: "permission", permission: Permission.GroupSubgroupManage },
            right: {
                type: "builder",
                op: "or",
                left: { type: "permission", permission: Permission.TopicCategoryManage },
                right: roleAssignPermissionObject,
            },
        },
        subLinks: {
            header: "Organization",
            data: [
                {
                    label: "Groups",
                    to: "/organization/groups",
                    permissions: { type: "permission", permission: Permission.GroupSubgroupManage },
                },
                {
                    label: "Topics",
                    to: "/organization/topics",
                    permissions: { type: "permission", permission: Permission.TopicCategoryManage },
                },
                {
                    label: "Users",
                    to: "/organization/users",
                    permissions: roleAssignPermissionObject,
                },
            ],
        },
    },
    {
        icon: IconSettings,
        label: "Settings",
        to: "/settings",
        position: "bottom",
        index: 2,
        subLinks: {
            header: "Settings",
            data: [
                { label: "Profile Info", to: "/settings", hash: "profile" },
                { label: "Locations", to: "/settings", hash: "locations" },
                { label: "Topics", to: "/settings", hash: "topics" },
                { label: "Groups", to: "/settings", hash: "groups", hideIf: (user) => user.groups.length == 0 },
            ],
        },
    },
];
