import { NotificationData, notifications } from "@mantine/notifications";
import { IconCheck, IconInfoCircle, IconX } from "@tabler/icons-react";
import { logger } from "shared/utils/logger";

export const notify = {
    ...notifications,
    catch: (error: unknown) => {
        logger.error(error);
        return notifications.show({
            color: "red",
            title: "Error",
            icon: <IconX size={20} />,
            message: error instanceof Error ? error.message : (error as object).toString(),
        });
    },
    show: {
        error: (props: NotificationData) => {
            return notifications.show({
                color: "red",
                title: "Error",
                icon: <IconX size={20} />,
                ...props,
            });
        },
        warn: (props: NotificationData) => {
            return notifications.show({
                color: "yellow",
                title: "Warning",
                icon: <IconInfoCircle size={20} />,
                ...props,
            });
        },
        info: (props: NotificationData) => {
            return notifications.show({
                color: "blue",
                title: "Info",
                icon: <IconInfoCircle size={20} />,
                ...props,
            });
        },
        success: (props: NotificationData | string) => {
            if (typeof props == "string") {
                return notifications.show({
                    color: "green",
                    title: "Success",
                    icon: <IconCheck size={20} />,
                    message: props,
                });
            }

            return notifications.show({
                color: "green",
                title: "Success",
                icon: <IconCheck size={20} />,
                ...props,
            });
        },
    },
} as const;
