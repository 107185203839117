import { Box, Button, Center, Container, Paper, Text, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";
import { useAuth } from "react-oidc-context";
import { BryxLogo } from "src/assets/BryxLogo";

const AcknowledgedComponent = () => {
    const auth = useAuth();

    return (
        <Center h="100dvh">
            <Container size={420} my={40}>
                <Box w={210} h={210} m="auto" mb="md">
                    <BryxLogo />
                </Box>
                <Title ta="center">Welcome back!</Title>
                <Text ta={"center"}>Your acknowledgement has been recorded, you may close this window or login</Text>
                <Paper withBorder shadow="md" p="lg" mt="lg" radius="md">
                    <Button w="100%" onClick={() => void auth.signinRedirect()}>
                        Continue to Login
                    </Button>
                </Paper>
            </Container>
        </Center>
    );
};

/** @public */
export const Route = createFileRoute("/acknowledged")({
    component: AcknowledgedComponent,
});
