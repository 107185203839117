import { Badge, Button, Group, Title, Tooltip } from "@mantine/core";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useParams } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import {
    ComposeFormProvider,
    composeFormStateToMessageInfoInput,
    createComposeFormState,
    useComposeForm,
} from "shared/components/message/compose/context";
import { useComposeFormPermissionChecker } from "shared/components/message/compose/useComposeFormPermissionChecker";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { logger } from "shared/utils/logger";
import { some } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

const Component = () => {
    const { id } = useParams({ from: "/_auth/messages/templates/$id" });
    const { data } = useSuspenseQuery(queries.template(id));
    const nav = useNavigate();

    const form = useComposeForm({
        initialValues: createComposeFormState(data.message),
    });
    const permissionChecker = useComposeFormPermissionChecker(form);

    const { mutateAsync: saveTemplate } = useGraphqlMutation(mutations.save);

    function handleSave() {
        const validation = form.validate();
        if (validation.hasErrors) {
            logger.warn(validation.errors);
            return;
        }

        some(form.getValues())
            .map(composeFormStateToMessageInfoInput.parse)
            .take((params) => saveTemplate({ id: data.message.id, params }))
            .then(() => qk.invalidate("templates"))
            .then(() => notify.show.success("Template Saved"))
            .then(() => nav({ to: "/messages/templates" }))
            .catch(notify.catch);
    }

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={1}>Edit Message</Title>
                <Badge color="blue">Template</Badge>
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Group justify="flex-end">
                <Tooltip {...permissionChecker.getTooltipProps()}>
                    <Button onClick={handleSave} disabled={permissionChecker.hasErrors}>
                        Save Template
                    </Button>
                </Tooltip>
            </Group>
        </Content>
    );
};

const queries = {
    template: (id: string) =>
        queryOptions({
            queryKey: qk("templates", "edit", id),
            select: ({ template }) => template,
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query TemplateEdit($id: UUID!) {
                            template(id: $id) {
                                message {
                                    id
                                    ...ComposeDetails
                                }
                            }
                        }
                    `),
                    { id },
                ),
        }),
};

const mutations = {
    save: graphql(`
        mutation TemplateEditSave($id: UUID!, $params: MessageInfoInput!) {
            updateMessage(id: $id, params: $params) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/templates/$id")({
    component: Component,
});
