import { ActionIcon, AppShell, Group, Menu, rem, Title } from "@mantine/core";
import { IconUserCircle } from "@tabler/icons-react";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import React from "react";
import { BryxLogo } from "src/assets/BryxLogo";

const Component = () => {
    const logoWidth = "30px";
    const logo = import.meta.env.VITE_ORG_LOGO_SOURCE;
    const title = import.meta.env.VITE_ORG_TITLE;
    const nav = useNavigate();

    return (
        <AppShell header={{ height: rem(60) }}>
            <AppShell.Header>
                <Group h="100%" px="md" justify="space-between">
                    <Group pl="xs">
                        <Group>
                            {Boolean(logo) ? <img src={logo} width={logoWidth} /> : <BryxLogo width={logoWidth} />}
                            <Title
                                order={2}
                                c="var(--mantine-primary-color-filled)"
                                fw={"light"}
                                // onDoubleClick={() =>
                                //     import.meta.env["VITE_ENV"] != "prod" &&
                                //     (() => (window.location.href = "https://bryx.slack.com/archives/C059BQRU7GE/p1736542695444959"))()
                                // }
                            >
                                {Boolean(title) ? title : "Bryx MNS"}
                            </Title>
                        </Group>
                    </Group>
                    <Menu>
                        <Menu.Target>
                            <ActionIcon variant="filled" aria-label="Profile" radius="100%" size="lg">
                                <IconUserCircle size={20} stroke={1.5} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item onClick={() => void nav({ to: "/logout" })}>Log out</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </AppShell.Header>
            <AppShell.Main>
                <Outlet />
            </AppShell.Main>
        </AppShell>
    );
};

/** @public */
export const Route = createFileRoute("/account")({
    component: Component,
});
