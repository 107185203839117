import { Button, Group, Loader, Title, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate, useParams } from "@tanstack/react-router";
import { Suspense } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import {
    ComposeFormProvider,
    composeFormStateToMessageInfoInput,
    createComposeFormState,
    useComposeForm,
} from "shared/components/message/compose/context";
import { SendPanel } from "shared/components/message/compose/SendPanel";
import { useComposeFormPermissionChecker } from "shared/components/message/compose/useComposeFormPermissionChecker";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { catching, voiding } from "shared/utils/fns";
import { some } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";

const Component = () => {
    const nav = useNavigate();
    const { id } = useParams({ from: "/_auth/messages/templates/send/$id" });
    const { data } = useSuspenseQuery(queries.templates(id));

    const { mutateAsync: saveDraft } = useGraphqlMutation(mutations.saveDraft);
    const { mutateAsync: sendMessage } = useGraphqlMutation(mutations.send);
    const { mutateAsync: discardDraft } = useGraphqlMutation(mutations.discardDraft);

    const form = useComposeForm({
        initialValues: createComposeFormState(data),
    });

    const permissionChecker = useComposeFormPermissionChecker(form);

    function handleSaveAsDraft() {
        some(form)
            .if((it) => !it.validate().hasErrors)
            ?.map((it) => it.getValues())
            .map(composeFormStateToMessageInfoInput.parse)
            .binding("params")
            .take(saveDraft)
            .then(({ draft: { id } }) => nav({ to: "/messages/drafts/$id", params: { id } }))
            .then(() => notify.show.success("Draft Saved!"))
            .catch(notify.catch);
    }

    async function handleReview() {
        if (form.validate().hasErrors) return;
        const params = composeFormStateToMessageInfoInput.parse(form.getValues());
        const { draft } = await saveDraft({ params });

        modals.open({
            title: "Are you sure?",
            onClose: () => catching(() => void discardDraft({ id: draft.id })),
            children: (
                <Suspense fallback={<Loader />}>
                    <SendPanel
                        msgId={draft.id}
                        onClose={() => modals.closeAll()}
                        onSend={({ expireAt }) =>
                            saveDraft({ params })
                                .then(({ draft }) => sendMessage({ id: draft.id, expireAt }))
                                .then(({ sendMessage }) => nav({ to: "/messages/$id", params: { id: sendMessage.id } }))
                                .then(() => notify.show.success("Message Sent!"))
                                .then(() => modals.closeAll())
                                .catch(notify.catch)
                        }
                    />
                </Suspense>
            ),
        });
    }

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={1}>Send Template</Title>
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Tooltip {...permissionChecker.getTooltipProps()}>
                <Group justify="flex-end" p="xs" w="min-content" wrap="nowrap" ml="auto">
                    <Button variant="transparent" onClick={handleSaveAsDraft} disabled={permissionChecker.hasErrors}>
                        Save as Draft
                    </Button>
                    <Button onClick={voiding(handleReview)} disabled={permissionChecker.hasErrors}>
                        Save & Review
                    </Button>
                </Group>
            </Tooltip>
        </Content>
    );
};

const queries = {
    templates: (id: string) =>
        queryOptions({
            queryKey: qk("templates", "composeDetail", id),
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query TemplateSendComposeDetails($id: UUID!) {
                            template(id: $id) {
                                message {
                                    ...ComposeDetails
                                }
                            }
                        }
                    `),
                    { id },
                ),
            select: ({ template }) => template.message,
        }),
};

const mutations = {
    saveDraft: graphql(`
        mutation TemplateSendComposeSaveDraft($params: MessageInfoInput!) {
            draft: createMessage(params: $params) {
                id
            }
        }
    `),
    discardDraft: graphql(`
        mutation TemplateSendComposeDiscardDraft($id: UUID!) {
            discardMessageDraft(id: $id) {
                id
            }
        }
    `),
    send: graphql(`
        mutation TemplateSendComposeSend($id: UUID!, $expireAt: Instant) {
            sendMessage(id: $id, expireAt: $expireAt) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/templates/send/$id")({
    component: Component,
});
