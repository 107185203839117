import { Stack, Text, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { flushSync } from "react-dom";
import { Content } from "shared/components/global/Content";
import { GroupDetailsForm } from "shared/components/organization/groups";
import { groupsQueryOptions, useCreateGroup } from "shared/graphql/groups";
import { infiniteUserQueryOptions } from "shared/graphql/users";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { GroupLikeFragment } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const group = { id: "create", name: "", members: [], children: [], parents: [], synchronized: false, internalJoinable: false, senders: [] };

const Page = () => {
    const nav = useNavigate();
    const isMobile = useIsMobile();

    const { createGroupAsync } = useCreateGroup();

    const form = useForm<GroupLikeFragment>({
        initialValues: group,
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (v.length == 0 ? "Group must have at least one parent" : null),
        },
    });

    function handleSubmit(data: GroupLikeFragment) {
        void createGroupAsync({
            name: data.name,
            parentGroupIds: data.parents.map((p) => p.id),
            internalJoinable: data.internalJoinable,
            members: [],
        })
            .then((it) => {
                // Fixes a bug where on redirect there's an "are you sure you want to leave unsaved changes"
                // popup, despite the changes really being saved.
                flushSync(form.reset);
                return it;
            })
            .then(({ createGroup: { id } }) => nav({ to: "/organization/groups/$groupId", params: { groupId: id } }));
    }

    return (
        <Stack p={!isMobile ? "1rem" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <GroupDetailsForm group={group} handleSubmit={handleSubmit} form={form} />
            <Content>
                <Title order={3}>Members</Title>
                <Stack align="center">
                    <Text c="dimmed">Finish creating the group to add members!</Text>
                </Stack>
            </Content>
        </Stack>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups/create")({
    component: Page,
    loader: () => {
        return Promise.all([
            queryClient.prefetchInfiniteQuery(infiniteUserQueryOptions()),
            queryClient.ensureQueryData(groupsQueryOptions.rootGroup),
        ]);
    },
});
