import { useLocation } from "@tanstack/react-router";
import { useEffect } from "react";

export function useOnNavAway(f: () => unknown) {
    const location = useLocation();

    useEffect(() => {
        f();
    }, [location]);
}
