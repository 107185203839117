import { Stack, Title } from "@mantine/core";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Content } from "shared/components/global/Content";
import { TopicNode } from "shared/components/organization/topics";
import { topicsQueryOptions, useAddUserToTopic, useRemoveUserFromTopic } from "shared/graphql/topics";
import { useUser } from "shared/graphql/users";
import { useUserId } from "shared/stores/oidc";
import { to } from "shared/utils/fns";

export const TopicsContainer = () => {
    const { user } = useUser();
    const { userId } = useUserId();

    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { addUser } = useAddUserToTopic();
    const { removeUser } = useRemoveUserFromTopic();

    return (
        <Content paper stack={{ gap: 0 }}>
            <Content.Heading>
                <Title order={3} id="topics">
                    Topic Subscriptions
                </Title>
            </Content.Heading>
            <Stack gap={0}>
                {rootTopic.children.map((c) => (
                    <TopicNode
                        key={c.id}
                        topic={c}
                        checkedTopicIds={user?.topics.map((t) => t.id) ?? []}
                        checkboxMode="controlled"
                        initialExpandedNodes={rootTopic.children.filter((t) => t.isCategory).map(to("id"))}
                        shouldRenderCheckbox={() => {
                            return true;
                        }}
                        shouldCheckRequiredTopics={true}
                        checkboxOnChange={(id, checked) => {
                            if (checked) {
                                addUser({ userId, topicId: id });
                            } else {
                                removeUser({ userId, topicId: id });
                            }
                        }}
                        disabledPredicate={(t) => t.isRequired}
                        disabledTooltipLabel="This topic is required and cannot be unsubscribed from"
                    />
                ))}
            </Stack>
        </Content>
    );
};
