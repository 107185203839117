import { Button, Group, Loader, Title, Tooltip } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { modals } from "@mantine/modals";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Suspense } from "react";
import { Content } from "shared/components/global/Content";
import { SplitButton } from "shared/components/global/SplitButton";
import {
    ComposeFormProvider,
    composeFormStateSchema,
    composeFormStateToMessageInfoInput,
    useComposeForm,
} from "shared/components/message/compose/context";
import { SendPanel } from "shared/components/message/compose/SendPanel";
import { useComposeFormPermissionChecker } from "shared/components/message/compose/useComposeFormPermissionChecker";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { catching } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { graphql } from "src/gql";
import { Cm, MessageSeverity } from "src/gql/graphql";

const Component = () => {
    const nav = useNavigate();
    const form = useComposeForm({
        validate: zodResolver(composeFormStateSchema),
        initialValues: {
            customizations: {
                sms: { enabled: false, value: "" },
                phone: { enabled: false, value: "" },
            },
            audience: {
                groups: [],
                topics: [],
                users: [],
                features: [],
                geofilters: [],
            },
            acknowledgable: false,
            escalations: [],
            severity: MessageSeverity.Informational,
            sender: { id: "" },
            body: "",
            richBody: "",
            options: [{ method: Cm.Email }, { method: Cm.Sms }],
            files: [],
            subject: "",
        },
    });

    const { mutateAsync: createDraft } = useGraphqlMutation(mutations.createDraft);
    const { mutateAsync: sendMessage } = useGraphqlMutation(mutations.sendMessage);
    const { mutateAsync: discardDraft } = useGraphqlMutation(mutations.discardDraft);

    function handleSaveAsDraft() {
        if (form.validate().hasErrors) return;

        createDraft({ params: composeFormStateToMessageInfoInput.parse(form.getValues()) })
            .then(async ({ createMessage: { subject, id } }) => {
                notify.show.success({ message: `Saved '${subject}'` });
                return nav({ to: "/messages/drafts/$id", params: { id } });
            })
            .catch(logger.error);
    }

    function handleSend() {
        if (form.validate().hasErrors) return;

        void createDraft({ params: composeFormStateToMessageInfoInput.parse(form.getValues()) }).then(({ createMessage: { id } }) =>
            modals.open({
                title: "Are you sure?",
                onClose: () => catching(() => void discardDraft({ id })),
                children: (
                    <Suspense fallback={<Loader />}>
                        <SendPanel
                            msgId={id}
                            onClose={() => modals.closeAll()}
                            onSend={({ expireAt }) => {
                                void createDraft({ params: composeFormStateToMessageInfoInput.parse(form.getValues()) })
                                    .then(async ({ createMessage: { id } }) => sendMessage({ id, expireAt }))
                                    .then(({ sendMessage: { id } }) => nav({ to: "/messages/$id", params: { id } }))
                                    .then(() => notify.show.success({ message: "Message sent!" }))
                                    .then(() => modals.closeAll());
                            }}
                        />
                    </Suspense>
                ),
            }),
        );
    }

    const permissionChecker = useComposeFormPermissionChecker(form);

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>New Message</Title>
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Tooltip {...permissionChecker.getTooltipProps()}>
                <Group justify="flex-end" p="xs" w="min-content" wrap="nowrap" ml="auto">
                    <Button variant="transparent" onClick={handleSaveAsDraft} disabled={permissionChecker.hasErrors}>
                        Save as Draft
                    </Button>

                    <SplitButton onClick={handleSend} menu={{ Schedule: { fn: () => {} } }} disabled={permissionChecker.hasErrors}>
                        Review and send
                    </SplitButton>
                </Group>
            </Tooltip>
        </Content>
    );
};

const mutations = {
    createDraft: graphql(`
        mutation ComposeCreateDraft($params: MessageInfoInput!) {
            createMessage(params: $params) {
                id
                subject
            }
        }
    `),
    sendMessage: graphql(`
        mutation ComposeSendMesage($id: UUID!, $expireAt: Instant) {
            sendMessage(id: $id, expireAt: $expireAt) {
                id
            }
        }
    `),
    discardDraft: graphql(`
        mutation ComposeSendPanelModalDiscardDraft($id: UUID!) {
            discardMessageDraft(id: $id) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/compose")({
    component: Component,
});
