import { Menu, Text, Title } from "@mantine/core";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { usePermissionCheck } from "shared/stores/oidc";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const messages = pages.flat();

    const canSendToGroup = usePermissionCheck(Permission.GroupMessageSendTo);
    const canSendToTopic = usePermissionCheck(Permission.TopicMessageSendTo);
    const canSend = canSendToGroup || canSendToTopic;

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Sent Messages</Title>
            </Content.Heading>
            {messages.length == 0 && (
                <NoMessagesFigure>
                    <Text ta="center" c="dimmed">
                        Looks like you haven't sent any messages yet!
                    </Text>
                </NoMessagesFigure>
            )}

            {messages.map((message) => (
                <Link to={"/messages/$id"} params={{ id: message.id }} key={message.id}>
                    <MessageDetailCard
                        unread
                        status={message.status}
                        title={message.subject}
                        timestamp={message.publishedAt ?? undefined}
                        severity={message.severity}
                        body={message.body}
                    />
                </Link>
            ))}

            <LoadMoreTrigger query={query} />

            {canSend && (
                <FloatingActionButton>
                    <Link to="/messages/compose">
                        <Menu.Item>New Message</Menu.Item>
                    </Link>
                </FloatingActionButton>
            )}
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query SentMessages($limit: Int!, $offset: Int!) {
            sent(limit: $limit, offset: $offset) {
                id
                subject
                body
                status
                severity
                publishedAt
                sender {
                    group {
                        id
                    }
                }
            }
        }
    `),
    queryKey: ["messages", "sent"],
    select: ({ sent }) => sent,
});

/**
 * @public
 */
export const Route = createFileRoute("/_auth/messages/sent")({
    shouldReload: false,
    component: Component,
    loader: () => queryClient.prefetchInfiniteQuery(query),
});
