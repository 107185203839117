import { ActionIcon, Button, ButtonProps, Group, Menu, PolymorphicComponentProps } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { forwardRef } from "react";
import classes from "shared/components/global/SplitButton/SplitButton.module.css";

type SplitButtonProps = PolymorphicComponentProps<"button", ButtonProps> & {
    menu: { [label: string]: { fn: () => unknown } };
};

export const SplitButton = forwardRef<HTMLButtonElement, SplitButtonProps>(({ menu, size = "sm", ...buttonProps }, ref) => {
    return (
        <Group wrap="nowrap" gap={0}>
            <Button ref={ref} {...buttonProps} className={classes.button} size={size} variant="filled" />
            <Menu position="bottom-end" withinPortal>
                <Menu.Target>
                    <ActionIcon variant="filled" size={36} className={classes.menuControl} disabled={buttonProps.disabled}>
                        <IconChevronDown size={16} stroke={1.5} />
                    </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                    {Object.entries(menu).map(([label, { fn }]) => (
                        <Menu.Item key={label} onClick={() => fn()}>
                            {label}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        </Group>
    );
});
