import { Badge, Menu, Text, Title, UnstyledButton } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconCopy, IconEdit, IconTrash } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { DetailCard } from "shared/components/global/DetailCard";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { usePermissionCheck } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const nav = useNavigate();
    const canSendToGroup = usePermissionCheck(Permission.GroupMessageSendTo);
    const canSendToTopic = usePermissionCheck(Permission.TopicMessageSendTo);
    const canSend = canSendToGroup || canSendToTopic;
    const canCreateTemplate = usePermissionCheck(Permission.GroupTemplateCreate);

    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const templates = pages.flat();

    const { mutateAsync: duplicateTemplate } = useGraphqlMutation(mutations.duplicate);
    const { mutateAsync: deleteTemplate } = useGraphqlMutation(mutations.delete);

    function handleDuplicate(id: string) {
        duplicateTemplate({ id })
            .then(() => qk.invalidate("templates"))
            .then(() => notify.show.success("Duplicated Template"))
            .catch(notify.catch);
    }

    function handleDelete(id: string) {
        modals.openConfirmModal({
            title: "Delete Template",
            children: <Text size="sm">Are you sure you want to delete this template?</Text>,
            labels: {
                confirm: "Delete",
                cancel: "Cancel",
            },
            onConfirm: () =>
                void deleteTemplate({ id })
                    .then(() => qk.invalidate("templates"))
                    .then(() => notify.show.success("Deleted Template"))
                    .catch(notify.catch),
        });
    }

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Templates</Title>
            </Content.Heading>

            {templates.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you don't have any Templates yet
                    </Text>
                </NoMessagesFigure>
            )}
            {(canSend || canCreateTemplate) && (
                <FloatingActionButton>
                    {canSend && (
                        <Link to="/messages/compose">
                            <Menu.Item>Create Message</Menu.Item>
                        </Link>
                    )}
                    {canCreateTemplate && (
                        <Link to="/messages/templates/create">
                            <Menu.Item>Create Template</Menu.Item>
                        </Link>
                    )}
                </FloatingActionButton>
            )}

            {templates.map((template) => (
                <UnstyledButton
                    key={template.id}
                    w="100%"
                    onClick={() => {
                        void nav({ to: "/messages/templates/send/$id", params: { id: template.id } });
                    }}
                >
                    <DetailCard
                        title={template.message.subject}
                        subtitle={template.owner.name}
                        badge={
                            <Badge radius={"sm"} variant="light" color={"indigo"}>
                                Template
                            </Badge>
                        }
                        actions={[
                            {
                                icon: <IconEdit size={16} />,
                                label: "Edit",
                                onClick: () => nav({ to: "/messages/templates/$id", params: { id: template.id } }),
                            },
                            { icon: <IconCopy size={16} />, label: "Duplicate", onClick: () => handleDuplicate(template.id) },
                            { icon: <IconTrash size={16} />, label: "Delete", onClick: () => handleDelete(template.id), color: "red" },
                        ]}
                    >
                        {template.message.body}
                    </DetailCard>
                </UnstyledButton>
            ))}

            <LoadMoreTrigger query={query} />
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query Templates($offset: Int!, $limit: Int!) {
            templates(offset: $offset, limit: $limit) {
                id
                owner {
                    name
                }
                message {
                    subject
                    body
                }
            }
        }
    `),
    queryKey: qk("templates", "list"),
    select: ({ templates }) => templates,
});

const mutations = {
    duplicate: graphql(`
        mutation TemplateListDuplicateTemplate($id: UUID!) {
            duplicateTemplate(id: $id) {
                id
            }
        }
    `),
    delete: graphql(`
        mutation TemplateListDeleteTemplate($id: UUID!) {
            deleteTemplate(id: $id) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/templates/")({
    loader: () => queryClient.prefetchInfiniteQuery(query),
    component: Component,
});
