import {
    ActionIcon,
    Box,
    Button,
    Card,
    Checkbox,
    Combobox,
    Divider,
    Group,
    Input,
    InputLabel,
    Loader,
    Menu,
    Pill,
    PillsInput,
    Popover,
    Stack,
    Switch,
    Text,
    TextInput,
    Title,
    useCombobox,
    useMantineTheme,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconChevronRight, IconDotsVertical, IconDownload, IconEdit, IconPlus, IconTrash } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useBlocker, useNavigate } from "@tanstack/react-router";
import { ReactNode, Suspense, useEffect, useState } from "react";
import { Content } from "shared/components/global/Content";
import { SenderForm, SenderFormState } from "shared/components/organization/senders";
import { groupsQueryOptions } from "shared/graphql/groups";
import { useCreateSender, useDeleteSender, useUpdateSender } from "shared/graphql/senders";
import { useColorMode } from "shared/hooks/useColorMode";
import { permissionCheck, permissionsCheck, usePermissionCheck, usePermissions } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { GroupLikeFragment, Permission, PermissionLikeFragment, SenderLikeFragment } from "src/gql/graphql";

export const GroupNode = ({
    group,
    checkboxOnClick,
    isDisabledPredicate,
    selectedCheckboxes,
    skipNodePredicate,
    renderLabel,
    shouldRenderCheckbox,
    initialExpand,
}: {
    group: GroupLikeFragment;
    initialExpand?: boolean;
    checkboxOnClick?: (node: GroupLikeFragment) => void;
    selectedCheckboxes?: string[];
    isDisabledPredicate?: (group: GroupLikeFragment) => boolean;
    skipNodePredicate?: (group: GroupLikeFragment) => boolean;
    renderLabel: (group: GroupLikeFragment) => ReactNode;
    shouldRenderCheckbox?: (group: GroupLikeFragment) => boolean;
}) => {
    const theme = useMantineTheme();
    const { isDark } = useColorMode();
    const [isExpanded, setIsExpanded] = useState(!!initialExpand);

    const { data } = useSuspenseQuery(groupsQueryOptions.children(group.id));

    const children = data.group.children.filter((c) => !skipNodePredicate?.(c as GroupLikeFragment)) as GroupLikeFragment[];

    const willRenderCheckbox = !!checkboxOnClick && !!selectedCheckboxes && (!!shouldRenderCheckbox ? shouldRenderCheckbox(group) : true);

    const expandButton: ReactNode = (
        <ActionIcon variant="subtle" color="text" onClick={() => setIsExpanded(!isExpanded)}>
            <IconChevronRight
                size={16}
                opacity={isDisabledPredicate?.(group) ? 0.4 : undefined}
                className={isExpanded ? "expandChevron" : "collapseChevron"}
            />
        </ActionIcon>
    );

    return (
        <Stack gap={0} w="min-content" style={{ display: "inline-flex" }}>
            <Group w="min-content" gap={0} p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                {willRenderCheckbox && (
                    <Checkbox
                        checked={selectedCheckboxes.includes(group.id)}
                        onChange={() => checkboxOnClick!(group)}
                        pr="sm"
                        disabled={isDisabledPredicate?.(group)}
                    />
                )}
                {(children?.length ?? 0) !== 0 && expandButton}
                {renderLabel(group)}
            </Group>
            <Suspense fallback={<Loader />}>
                {isExpanded && (
                    <Stack
                        gap={0}
                        w="fit-content"
                        style={{
                            borderLeft: `var(--mantine-spacing-md) solid ${isDark ? theme.colors.dark[5] : theme.colors.gray[1]}`,
                            overflow: "auto",
                        }}
                    >
                        {children?.map((c) => (
                            <GroupNode
                                key={`${group.id}+${c.id}`}
                                group={c}
                                skipNodePredicate={skipNodePredicate}
                                checkboxOnClick={checkboxOnClick}
                                isDisabledPredicate={isDisabledPredicate}
                                selectedCheckboxes={selectedCheckboxes}
                                renderLabel={renderLabel}
                                shouldRenderCheckbox={shouldRenderCheckbox}
                            />
                        ))}
                    </Stack>
                )}
            </Suspense>
        </Stack>
    );
};

export function sharedOrgPageGroupDisabledCheck(permissions: PermissionLikeFragment, group: GroupLikeFragment) {
    return !permissionCheck(permissions, Permission.GroupSubgroupManage, group.id);
}

export const SharedOrgPageGroupLabel = (group: GroupLikeFragment) => {
    const nav = useNavigate();
    const { permissions } = usePermissions();

    return sharedOrgPageGroupDisabledCheck(permissions, group) ? (
        <Text opacity={0.4}>{group.name}</Text>
    ) : (
        <Text>
            <Group wrap="nowrap">
                {group.name}
                <ActionIcon
                    variant="transparent"
                    onClick={() => void nav({ to: `/organization/groups/$groupId`, params: { groupId: group.id } }).catch(logger.error)}
                >
                    <IconEdit size={16} />
                </ActionIcon>
            </Group>
        </Text>
    );
};

const SendersManage = ({ groupId }: { groupId: string }) => {
    const {
        data: { senders },
    } = useSuspenseQuery(groupsQueryOptions.groupSenders(groupId));

    const { createSender } = useCreateSender();
    const { updateSender } = useUpdateSender();
    const { deleteSender } = useDeleteSender(groupId);

    function openDeleteSenderModal(senderId: string) {
        modals.openConfirmModal({
            title: "Delete sender?",
            centered: true,
            children: <Text>Are you sure you want to permanently delete this sender? This action cannot be undone.</Text>,
            labels: { confirm: "Delete Sender", cancel: "Cancel" },
            onConfirm: () => deleteSender({ senderId }),
        });
    }

    function openSenderForm(handleSubmit: (data: SenderFormState) => void, initialSender?: SenderLikeFragment) {
        modals.open({
            title: "Sender Profile",
            children: (
                <Suspense fallback={<Loader />}>
                    <SenderForm initialSender={initialSender} handleSubmit={handleSubmit} />
                </Suspense>
            ),
        });
    }

    return (
        <>
            <Title order={3}>Sender Profiles</Title>
            <Group pt="sm">
                {senders.map((sender) => (
                    <Group
                        key={sender.id}
                        p="sm"
                        gap="xs"
                        style={{ border: "1px solid var(--mantine-color-text)", borderRadius: "0.5rem" }}
                    >
                        <Stack gap={0}>
                            <Group gap={0} align="flex-start" justify="space-between">
                                <Title order={4} mb="xs">
                                    {sender.name}
                                </Title>
                                <Menu>
                                    <Menu.Target>
                                        <ActionIcon variant="transparent">
                                            <IconDotsVertical size={16} />
                                        </ActionIcon>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <Menu.Item
                                            onClick={() =>
                                                openSenderForm((data) => {
                                                    updateSender({
                                                        senderId: sender.id,
                                                        params: {
                                                            name: data.name,
                                                            fromEmailAddress: data.fromEmail,
                                                            fromPhone: data.fromPhone,
                                                            verificationSender: sender.verificationSender,
                                                        },
                                                    });
                                                    modals.closeAll();
                                                }, sender)
                                            }
                                            leftSection={<IconEdit size={16} />}
                                        >
                                            Edit
                                        </Menu.Item>
                                        <Menu.Item
                                            color="red"
                                            onClick={() => openDeleteSenderModal(sender.id)}
                                            leftSection={<IconTrash size={16} />}
                                        >
                                            Delete
                                        </Menu.Item>
                                    </Menu.Dropdown>
                                </Menu>
                            </Group>
                            {sender.fromEmail && <Text>Email: {sender.fromEmail}</Text>}
                            {sender.fromPhone && <Text>Phone: {sender.fromPhone}</Text>}
                        </Stack>
                    </Group>
                ))}
                <Button
                    variant="transparent"
                    leftSection={<IconPlus size={16} />}
                    onClick={() => {
                        openSenderForm((data) => {
                            createSender({ ...data, groupId });
                            modals.closeAll();
                        });
                    }}
                >
                    Add
                </Button>
            </Group>
        </>
    );
};

export const GroupDetailsForm = ({
    form,
    group,
    handleSubmit,
}: {
    form: UseFormReturnType<GroupLikeFragment>;
    group: GroupLikeFragment;
    handleSubmit: (data: GroupLikeFragment) => void;
}) => {
    const { reset } = form;
    const hasSenderManage = usePermissionCheck(Permission.GroupSenderManage, group.id);

    useEffect(() => {
        form.setInitialValues({ ...group });
        reset();
    }, [group]);

    useBlocker({
        blockerFn: () => {
            if (window.confirm("This action will lose currently unsaved changes. Continue anyways?")) {
                reset();
                return false;
            }
            return true;
        },
        condition: form.isDirty(),
    });

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.preventDefault();
                handleSubmit(data);
            })}
        >
            <Content>
                <Group>
                    <Title order={3}>Details</Title>
                    <Group wrap="nowrap" pl="md">
                        <Button color="text" type="submit" disabled={!form.isDirty()} leftSection={<IconDownload size={16} />}>
                            Save
                        </Button>

                        <Button
                            variant="default"
                            onClick={(e) => {
                                e.preventDefault();
                                reset();
                            }}
                            disabled={!form.isDirty()}
                            type="button"
                        >
                            Reset
                        </Button>
                    </Group>
                </Group>
                <TextInput key={form.key("name")} label="Group Name" placeholder="Enter name" {...form.getInputProps("name")} />
                <Stack gap={0} pt="sm">
                    <InputLabel>Parent Group(s)</InputLabel>
                    <GroupTreeCombobox
                        initialSelectedGroups={form.getValues().parents ?? []}
                        onChange={(parents) => form.setValues({ parents })}
                        disabled={group.synchronized}
                        currentGroup={group}
                        errorText={(form.errors["parents"] as string) ?? undefined}
                    />
                </Stack>
                <Switch
                    key={form.key("internalJoinable")}
                    label="Joinable"
                    w="fit-content"
                    py="sm"
                    size="lg"
                    disabled={group.synchronized}
                    {...form.getInputProps("internalJoinable", { type: "checkbox" })}
                />
                {hasSenderManage && <SendersManage groupId={group.id} />}
            </Content>
        </form>
    );
};

type GroupNameAndId = { name: string; id: string };

export const GroupTreeCombobox = ({
    currentGroup,
    disabled,
    initialSelectedGroups,
    onChange,
    errorText,
    target,
    shouldRenderCheckbox,
    disabledPredicate,
}: {
    currentGroup?: GroupLikeFragment;
    initialSelectedGroups?: GroupNameAndId[];
    onChange: ((parentIds: GroupNameAndId[]) => void) | ((parentIds: GroupNameAndId[]) => Promise<void>);
    disabled?: boolean;
    errorText?: string;
    target?: (onClick: () => void) => ReactNode;
    shouldRenderCheckbox?: (group: GroupLikeFragment) => boolean;
    disabledPredicate?: (group: GroupLikeFragment) => boolean;
}) => {
    const combobox = useCombobox();
    const { permissions } = usePermissions();
    const [popoverOpened, setPopoverOpened] = useState(false);

    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !!disabledPredicate
            ? disabledPredicate(g)
            : sharedOrgPageGroupDisabledCheck(permissions, g) || currentGroup?.synchronized || g.synchronized;
    }

    const GroupSelectModal = () => {
        const [selectedCheckboxes, setSelectedCheckboxes] = useState<GroupNameAndId[]>(initialSelectedGroups ?? []);

        return (
            <Box>
                <Card mah="75dvh" style={{ overflowY: "auto" }}>
                    <GroupNode
                        group={rootGroup}
                        initialExpand={true}
                        checkboxOnClick={(node: GroupLikeFragment) => {
                            setSelectedCheckboxes(
                                selectedCheckboxes.some((g) => g.id == node.id)
                                    ? selectedCheckboxes.filter((g) => g.id != node.id)
                                    : selectedCheckboxes.concat(node),
                            );
                        }}
                        selectedCheckboxes={selectedCheckboxes.map((g) => g.id)}
                        isDisabledPredicate={isDisabledPredicate}
                        skipNodePredicate={(g) => g.id == currentGroup?.id}
                        renderLabel={(g) => <Text opacity={isDisabledPredicate(g) ? 0.4 : undefined}>{g.name}</Text>}
                        shouldRenderCheckbox={shouldRenderCheckbox}
                    />
                </Card>
                <Divider />
                <Group pt="sm" pos="sticky">
                    <Button variant="default" onClick={modals.closeAll} style={{ flexGrow: 1 }}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onChange(selectedCheckboxes)?.catch(logger.error);
                            modals.closeAll();
                        }}
                        style={{ flexGrow: 1 }}
                    >
                        Continue
                    </Button>
                </Group>
            </Box>
        );
    };

    function openGroupSelectModal() {
        modals.open({
            title: "Select Groups",
            children: (
                <Suspense fallback={<Loader />}>
                    <GroupSelectModal />
                </Suspense>
            ),
            centered: true,
        });
    }

    return (
        <>
            <Combobox store={combobox}>
                <Combobox.DropdownTarget>
                    {!!target ? (
                        target(openGroupSelectModal)
                    ) : (
                        <PillsInput
                            pointer
                            onClick={() => {
                                if (!disabled && currentGroup?.id !== rootGroup.id) openGroupSelectModal();
                            }}
                            disabled={disabled || currentGroup?.id == rootGroup.id}
                            error={errorText}
                        >
                            <Pill.Group>
                                {!!initialSelectedGroups && initialSelectedGroups.length > 0 ? (
                                    initialSelectedGroups.map((g) => {
                                        const canRemoveParent = permissionsCheck(permissions, [
                                            [Permission.GroupSubgroupManage, g.id],
                                            [Permission.GroupSubgroupManage, currentGroup?.id],
                                        ]);
                                        return (
                                            <Popover
                                                disabled={canRemoveParent}
                                                opened={popoverOpened}
                                                onChange={setPopoverOpened}
                                                key={g.id}
                                                position="top"
                                            >
                                                <Popover.Target>
                                                    <Pill
                                                        withRemoveButton
                                                        disabled={disabled}
                                                        onRemove={() => {
                                                            if (canRemoveParent)
                                                                onChange(initialSelectedGroups.filter((x) => x.id != g.id))?.catch(
                                                                    logger.error,
                                                                );
                                                            else setPopoverOpened(true);
                                                        }}
                                                    >
                                                        {g.name}
                                                    </Pill>
                                                </Popover.Target>
                                                <Popover.Dropdown>
                                                    <Text size="sm">You do not have permission to remove this parent</Text>
                                                </Popover.Dropdown>
                                            </Popover>
                                        );
                                    })
                                ) : currentGroup?.id == rootGroup.id ? (
                                    <Text fs="italic">This group cannot have any parents</Text>
                                ) : (
                                    <Input.Placeholder>Pick one or more values</Input.Placeholder>
                                )}
                                <Combobox.EventsTarget>
                                    <PillsInput.Field
                                        type="hidden"
                                        onBlur={() => combobox.closeDropdown()}
                                        onKeyDown={(event) => {
                                            if (event.key === "Backspace") {
                                                event.preventDefault();
                                                onChange(initialSelectedGroups?.slice(0, -1) ?? [])?.catch(logger.error);
                                            }
                                        }}
                                    />
                                </Combobox.EventsTarget>
                            </Pill.Group>
                        </PillsInput>
                    )}
                </Combobox.DropdownTarget>
            </Combobox>
        </>
    );
};
