import { Menu, Text, Title } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconClockCancel } from "@tabler/icons-react";
import { useSuspenseInfiniteQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Content } from "shared/components/global/Content";
import { FloatingActionButton } from "shared/components/global/FloatingActionButton/FloatingActionButton";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { MessageDetailCard } from "shared/components/message/MessageDetailCard";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { useOptimisticUpdater } from "shared/hooks/useOptimisicUpdater";
import { usePermissionCheck } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { createOffsetBasedGqlQueryOptions } from "shared/utils/query";
import { NoMessagesFigure } from "src/assets/NoMessagesFigure";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const Component = () => {
    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(query);
    const { updater } = useOptimisticUpdater(query);

    const canSendToGroup = usePermissionCheck(Permission.GroupMessageSendTo);
    const canSendToTopic = usePermissionCheck(Permission.TopicMessageSendTo);
    const canSend = canSendToGroup || canSendToTopic;

    const { mutateAsync: unschedule } = useGraphqlMutation(
        graphql(`
            mutation ScheduleListUnscheduleMessage($id: UUID!) {
                unscheduleMessage(id: $id) {
                    id
                    subject
                }
            }
        `),
    );

    function handleCancel(id: string) {
        modals.openConfirmModal({
            centered: true,
            title: "Cancel Scheduled Message",
            children: "Are you sure you want to cancel this schedule message? It will still be available as a draft.",
            labels: {
                confirm: "Cancel Message",
                cancel: "Dismiss",
            },
            onConfirm: () => {
                updater.delete(id);
                void unschedule({ id }).then(({ unscheduleMessage: { subject } }) =>
                    notify.show.success({
                        message: `Cancelled "${subject}"`,
                    }),
                );
            },
        });
    }

    const messages = pages.flat();

    return (
        <Content>
            <Content.Heading>
                <Title order={3}>Scheduled Messages</Title>
            </Content.Heading>

            {messages.length == 0 && (
                <NoMessagesFigure>
                    <Text ta={"center"} c="dimmed">
                        Looks like you haven't scheduled any messages yet!
                    </Text>
                </NoMessagesFigure>
            )}
            {messages.map((message) => (
                <MessageDetailCard
                    unread
                    key={message.id}
                    title={message.subject}
                    severity={message.severity}
                    status={message.status}
                    timestamp={message.scheduledFor ?? undefined}
                    body={message.body}
                    actions={[
                        {
                            icon: <IconClockCancel size={16} />,
                            label: "Cancel Schedule",
                            onClick: () => handleCancel(message.id),
                        },
                    ]}
                />
            ))}

            <LoadMoreTrigger query={query} />

            {canSend && (
                <FloatingActionButton>
                    <Link to="/messages/compose">
                        <Menu.Item>New Message</Menu.Item>
                    </Link>
                </FloatingActionButton>
            )}
        </Content>
    );
};

const query = createOffsetBasedGqlQueryOptions({
    document: graphql(`
        query ScheduledMessages($offset: Int!, $limit: Int!) {
            scheduled(limit: $limit, offset: $offset) {
                id
                body
                subject
                scheduledFor
                severity
                status
            }
        }
    `),
    select: ({ scheduled }) => scheduled,
    queryKey: ["messages", "scheduled"],
});

/** @public */
export const Route = createFileRoute("/_auth/messages/scheduled")({
    shouldReload: false,
    component: Component,
    loader: () => queryClient.prefetchInfiniteQuery(query),
});
