import { createFormContext } from "@mantine/form";
import { StrictUndef } from "shared/api/httpClient";
import { createRandomStr, to } from "shared/utils/fns";
import { some } from "shared/utils/maybe";
import { geozod, nonemptyString, nonemptyUUID } from "shared/utils/zod";
import { Cm, ComposeDetailsFragment, MessageSeverity } from "src/gql/graphql";
import { z } from "zod";

export const composeFormStateSchema = z.object({
    audience: z.object({
        groups: z.object({ id: z.string(), name: z.string() }).array(),
        topics: z.object({ id: z.string(), name: z.string() }).array(),
        users: z.object({ id: z.string(), name: z.string().optional() }).array(),
        features: geozod.feature(geozod.polygon()).array(),
        geofilters: z.string().uuid().array(),
    }),
    customizations: z.object({
        sms: z.object({
            value: z.string(),
            enabled: z.boolean(),
        }),
        phone: z.object({
            value: z.string(),
            enabled: z.boolean(),
        }),
    }),
    sender: z.object({ id: nonemptyUUID() }),
    severity: z.nativeEnum(MessageSeverity),
    subject: nonemptyString(),
    body: z.string(),
    richBody: z.string(),
    files: z.object({ id: nonemptyUUID() }).array(),
    options: z.object({ method: z.nativeEnum(Cm) }).array(),
    acknowledgable: z.boolean(),
    escalations: z.object({ delayMin: z.number() }).array(),
});

export type ComposeFormState = z.infer<typeof composeFormStateSchema>;

export const [ComposeFormProvider, useComposeFormContext, useComposeForm] = createFormContext<ComposeFormState>();

export function createComposeFormState(incoming: StrictUndef<ComposeDetailsFragment>): ComposeFormState {
    return {
        ...incoming,
        escalations: incoming.escalations.map(({ delaySec }) => ({
            delayMin:
                some(delaySec)
                    .if((it) => it >= 60 * 5)
                    ?.take((it) => Math.floor(it / 60)) ?? 5,
        })),
        customizations: {
            sms: incoming.customizations.find((it) => it.method == Cm.Sms) ?? { enabled: false, value: "" },
            phone: incoming.customizations.find((it) => it.method == Cm.Phone) ?? { enabled: false, value: "" },
        },
        audience: {
            ...incoming.audience,
            geofilters: incoming.audience.geofilters.map(to("id")),
            features: incoming.audience.geometries
                .map((s) => JSON.parse(s))
                .map((geometry) => geozod.feature(geozod.polygon()).parse({ type: "Feature", id: createRandomStr(), geometry })),
        },
    };
}

export const composeFormStateToMessageInfoInput = composeFormStateSchema.transform((state) => ({
    ...state,
    sender: state.sender.id,
    questions: [],
    audience: {
        groups: state.audience.groups.map(to("id")),
        topics: state.audience.topics.map(to("id")),
        users: state.audience.users.map(to("id")),
        geometries: state.audience.features.map((f) => JSON.stringify(f.geometry)),
        geofilters: state.audience.geofilters,
    },
    files: state.files.map(to("id")),
    escalations: state.severity == MessageSeverity.Urgent ? state.escalations.map((it) => ({ delaySec: it.delayMin * 60 })) : [],
    acknowledgable: state.severity == MessageSeverity.Urgent && state.acknowledgable,
    customizations: [
        { method: Cm.Sms, ...state.customizations.sms },
        { method: Cm.Phone, ...state.customizations.phone },
    ],
}));
