import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Divider,
    Group,
    Loader,
    Menu,
    Radio,
    RadioGroup,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { useField } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconArrowRight, IconChevronRight, IconDots, IconEdit, IconInfoCircle, IconPlus } from "@tabler/icons-react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { Suspense, useState } from "react";
import { Content } from "shared/components/global/Content";
import { CategoryParentSelect } from "shared/components/organization/topics";
import { topicsQueryOptions, useCreateCategory } from "shared/graphql/topics";
import { useColorMode } from "shared/hooks/useColorMode";
import { usePermissionCheck } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { Permission, TopicLikeFragment } from "src/gql/graphql";
import { queryClient } from "src/queryClient";

const CategoryCreateModal = ({ parentCategory }: { parentCategory: TopicLikeFragment }) => {
    const { createCategory } = useCreateCategory();
    const [parentId, setParentId] = useState(parentCategory.id);

    const title = useField({ initialValue: "", validate: (val) => (val.length < 1 ? "Enter a title" : null) });

    return (
        <Stack>
            <TextInput label="Title" withAsterisk {...title.getInputProps()} />
            <CategoryParentSelect parentId={parentId} setParentId={setParentId} />
            <Divider />
            <Button
                w="100%"
                onClick={() => {
                    createCategory({ title: title.getValue(), parent: parentId });
                    modals.closeAll();
                }}
            >
                Create
            </Button>
        </Stack>
    );
};

const TopicCreationModal = ({ onSubmit }: { onSubmit: (required: boolean) => void }) => {
    const [radioValue, setRadioValue] = useState("standard");
    return (
        <Stack>
            <Group>
                <IconInfoCircle size={16} />
                <Text size="lg">Topic Type cannot be changed</Text>
            </Group>
            <Text>Once you create this topic, the selected topic type will be permanent and cannot be edited later.</Text>
            <Divider />
            <RadioGroup value={radioValue} onChange={setRadioValue}>
                <Stack>
                    <Radio value="standard" label="Standard" />
                    <Radio value="required" label="Required" />
                </Stack>
            </RadioGroup>
            <Button w="100%" rightSection={<IconArrowRight size={16} />} onClick={() => onSubmit(radioValue == "required")}>
                Continue
            </Button>
        </Stack>
    );
};

const TopicDetails = ({ topic, handleTopicCreate }: { topic: TopicLikeFragment; handleTopicCreate: (categoryId: string) => void }) => {
    const nav = useNavigate();
    const theme = useMantineTheme();
    const { isDark } = useColorMode();
    const [opened, { toggle }] = useDisclosure(false);

    const { data } = useQuery({ ...topicsQueryOptions.topicChildren(topic.id), enabled: topic.isCategory });
    const children = data?.children;

    const hasEditPermission = usePermissionCheck(Permission.TopicCategoryManage, topic.id);

    return (
        <Stack w="100%" gap={0}>
            <Group key={topic.id} w="100%" py="xs" gap={0}>
                {!!children && children.length > 0 && (
                    <ActionIcon variant="transparent" onClick={toggle} ml={-6}>
                        <IconChevronRight
                            size={16}
                            style={{ transition: "transform 0.5s", transform: opened ? "rotate(90deg)" : "rotate(0deg)" }}
                        />
                    </ActionIcon>
                )}
                <Text px="sm">{topic.name}</Text>
                {hasEditPermission ? (
                    topic.isCategory ? (
                        <Menu>
                            <Menu.Target>
                                <ActionIcon variant="transparent">
                                    <IconDots size={16} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() =>
                                        modals.open({
                                            title: "Create Category",
                                            children: (
                                                <Suspense fallback={<Loader />}>
                                                    <CategoryCreateModal parentCategory={topic} />
                                                </Suspense>
                                            ),
                                        })
                                    }
                                >
                                    Create Category
                                </Menu.Item>
                                <Menu.Item onClick={() => handleTopicCreate(topic.id)}>Create Topic</Menu.Item>
                                <Menu.Item onClick={() => void nav({ to: "/organization/topics/$topicId", params: { topicId: topic.id } })}>
                                    Edit Category
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    ) : (
                        <ActionIcon
                            variant="transparent"
                            onClick={() => void nav({ to: "/organization/topics/$topicId", params: { topicId: topic.id } })}
                        >
                            <IconEdit size={16} />
                        </ActionIcon>
                    )
                ) : (
                    topic.isRequired && <Badge ml="sm">Required</Badge>
                )}
            </Group>
            {topic.isCategory &&
                opened &&
                children?.map((c) => (
                    <Box
                        key={c.id}
                        style={{ borderLeft: `var(--mantine-spacing-md) solid ${isDark ? theme.colors.dark[5] : theme.colors.gray[1]}` }}
                    >
                        <TopicDetails topic={c} handleTopicCreate={handleTopicCreate} />
                    </Box>
                ))}
        </Stack>
    );
};

const TopicsContainer = () => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const {
        data: { children },
    } = useSuspenseQuery(topicsQueryOptions.topicChildren(rootTopic.id));

    const nav = useNavigate();
    const hasRootEditPermission = usePermissionCheck(Permission.TopicCategoryManage, rootTopic.id);

    function handleTopicCreate(categoryId: string) {
        modals.open({
            title: "Topic Type",
            children: (
                <TopicCreationModal
                    onSubmit={(required) => {
                        modals.closeAll();
                        nav({
                            to: "/organization/topics/$topicId",
                            params: { topicId: "create" },
                            search: { required, category: categoryId },
                        }).catch(logger.error);
                    }}
                />
            ),
        });
    }

    return (
        <Content paper>
            <Content.Heading>
                <Group gap={0}>
                    <Title order={2} fw="normal">
                        Topics
                    </Title>
                    {hasRootEditPermission && (
                        <Menu>
                            <Menu.Target>
                                <ActionIcon variant="transparent">
                                    <IconPlus size={16} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() =>
                                        modals.open({
                                            title: "Create Category",
                                            children: (
                                                <Suspense fallback={<Loader />}>
                                                    <CategoryCreateModal parentCategory={rootTopic} />
                                                </Suspense>
                                            ),
                                        })
                                    }
                                >
                                    Create Category
                                </Menu.Item>
                                <Menu.Item onClick={() => handleTopicCreate(rootTopic.id)}>Create Topic</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )}
                </Group>
            </Content.Heading>
            <Stack gap={0} h="100%" align="flex-start" style={{ overflow: "auto" }}>
                {children.map((c) => (
                    <TopicDetails key={c.id} topic={c} handleTopicCreate={handleTopicCreate} />
                ))}
            </Stack>
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/organization/topics/")({
    component: TopicsContainer,
    loader: async () => {
        await queryClient.ensureQueryData(topicsQueryOptions.rootTopic);
    },
});
