import { Button, Group, Title } from "@mantine/core";
import { zodResolver } from "@mantine/form";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import {
    ComposeFormProvider,
    composeFormStateSchema,
    composeFormStateToMessageInfoInput,
    useComposeForm,
} from "shared/components/message/compose/context";
import { ComposeForm } from "shared/components/message/ComposeForm";
import { useFormState } from "shared/hooks/useFormState";
import { useGraphqlMutation } from "shared/hooks/useGraphql";
import { some } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm, MessageSeverity } from "src/gql/graphql";
import { z } from "zod";

const Component = () => {
    const nav = useNavigate();
    const form = useComposeForm({
        validate: zodResolver(composeFormStateSchema),
        initialValues: {
            audience: {
                groups: [],
                topics: [],
                users: [],
                features: [],
                geofilters: [],
            },
            customizations: {
                sms: { enabled: false, value: "" },
                phone: { enabled: false, value: "" },
            },
            acknowledgable: false,
            escalations: [],
            severity: MessageSeverity.Informational,
            sender: { id: "" },
            body: "",
            richBody: "",
            options: [{ method: Cm.Email }, { method: Cm.Sms }],
            files: [],
            subject: "",
        },
    });

    const [senderId] = useFormState(form, "sender.id");

    const { data: senderData } = useQuery(queries.senderOwner(senderId));
    const { mutateAsync: createTemplate } = useGraphqlMutation(mutations.create);

    function handleCreate() {
        if (!senderData) return;
        if (form.validate().hasErrors) return;

        some(form.getValues())
            .map(composeFormStateToMessageInfoInput.parse)
            .take((message) => createTemplate({ info: { message } }))
            .then(() => notify.show.success("Templated Created!"))
            .then(() => qk.invalidate("templates"))
            .then(() => nav({ to: "/messages/templates" }))
            .catch(notify.catch);
    }

    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={3}>New Template</Title>
            </Content.Heading>

            <ComposeFormProvider form={form}>
                <ComposeForm />
            </ComposeFormProvider>

            <Group justify="flex-end">
                <Button onClick={handleCreate}>Create</Button>
            </Group>
        </Content>
    );
};

const queries = {
    senderOwner: (senderId: string) =>
        queryOptions({
            queryKey: qk("templates", "senderOwner", senderId),
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query TemplateCreateSenderOwner($senderId: UUID!) {
                            sender(id: $senderId) {
                                group {
                                    id
                                }
                            }
                        }
                    `),
                    { senderId },
                ),
            enabled: () => z.string().uuid().safeParse(senderId).success,
            select: ({ sender }) => sender,
        }),
};

const mutations = {
    create: graphql(`
        mutation TemplateCreate($info: TemplateInfoInput!) {
            createTemplate(info: $info) {
                id
            }
        }
    `),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/templates/create")({
    component: Component,
});
