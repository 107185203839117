import { ActionIcon, Badge, Group, Loader, SegmentedControl, Stack, Text, Title, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { queryOptions, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { Suspense, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { Content } from "shared/components/global/Content";
import { InsightsChart } from "shared/components/message/insights/chart";
import { InsightsTable } from "shared/components/message/insights/table";
import { MessageStatusBadge } from "shared/components/message/MessageStatusBadge";
import { arr } from "shared/utils/array";
import { catching, titlecase } from "shared/utils/fns";
import { maybe } from "shared/utils/maybe";
import { qk } from "shared/utils/qk";
import { graphql } from "src/gql";
import { Cm } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { z } from "zod";

const Page = () => {
    const { id } = useParams({ from: "/_auth/messages/$id/insights" });
    const { data } = useSuspenseQuery(query.details(id));
    const [scope, setScope] = useState<Cm>();

    const { refetch: refetchTable } = useSuspenseInfiniteQuery(InsightsTable.query.metrics(id, scope, data.acknowledgable));

    return (
        <>
            <Content paper>
                <Content.Heading backable>
                    <Title order={1}>Insights</Title>
                </Content.Heading>

                <Group justify="space-between" align="flex-start">
                    <Stack gap="xs">
                        <Title order={1}>{data.subject}</Title>
                        <Text size="sm">{titlecase(data.severity)}</Text>
                        <Text size="sm">{data.sender.name}</Text>
                        <Text size="sm">{data.publishedBy?.name}</Text>
                    </Stack>
                    <Stack align="flex-end" gap="xs">
                        <MessageStatusBadge status={data.status} />
                        {maybe(data.publishedAt)
                            ?.map((it) => catching(() => z.coerce.number().pipe(z.coerce.date()).parse(it)))
                            ?.take((it) => <Text size="sm">{it.toLocaleString()}</Text>) ?? (
                            <Badge color="red" variant="filled">
                                Unknown or Missing Timestamp
                            </Badge>
                        )}
                    </Stack>
                </Group>

                <Title order={2}>Audience</Title>
                <Stack gap="xs">
                    <Title order={3}>Groups</Title>
                    <Group>
                        {maybe(data.audience.groups)
                            .takeIf((it) => it.length > 0)
                            ?.map(({ id, name }) => (
                                <Badge variant="filled" key={id}>
                                    {name}
                                </Badge>
                            )) ?? (
                            <Text size="sm" c="dimmed" fs="italic" mt="-xs">
                                No Groups
                            </Text>
                        )}
                    </Group>
                </Stack>

                <Stack gap="xs">
                    <Title order={3}>Topics</Title>
                    <Group>
                        {maybe(data.audience.topics)
                            .takeIf((it) => it.length > 0)
                            ?.map(({ id, name }) => (
                                <Badge variant="filled" key={id}>
                                    {name}
                                </Badge>
                            )) ?? (
                            <Text size="sm" c="dimmed" fs="italic" mt="-xs">
                                No Topics
                            </Text>
                        )}
                    </Group>
                </Stack>
            </Content>

            <Content paper>
                <Group justify="space-between" align="flex-start">
                    <Group gap="xs">
                        <SegmentedControl
                            w="fit-content"
                            onChange={(it) => setScope(it == "ALL" ? undefined : (it as Cm))}
                            data={arr(Object.values(Cm)).prepend("ALL").except(Cm.Webhook).take()}
                        />
                        <Tooltip label="Refresh table">
                            <ActionIcon variant="transparent" onClick={() => void refetchTable()}>
                                <IconRefresh size={16} />
                            </ActionIcon>
                        </Tooltip>
                    </Group>

                    <Suspense fallback={<Loader />}>
                        <InsightsChart msgId={id} cm={scope} />
                    </Suspense>
                </Group>

                <Suspense fallback={<Loader />}>
                    <InsightsTable msgId={id} cm={scope} ackable={data.acknowledgable} />
                </Suspense>
            </Content>
        </>
    );
};

const query = {
    details: (id: string) =>
        queryOptions({
            queryKey: qk("messages", "insights", "detail", id),
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query InsightsDetails($id: UUID!) {
                            message(id: $id) {
                                subject
                                status
                                severity
                                publishedAt
                                acknowledgable
                                publishedBy {
                                    name
                                }
                                sender {
                                    name
                                }
                                audience {
                                    groups {
                                        id
                                        name
                                    }
                                    topics {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    `),
                    { id },
                ),
            select: ({ message }) => message,
        }),
};

/** @public */
export const Route = createFileRoute("/_auth/messages/$id/insights")({
    loader: ({ params: { id } }) => void queryClient.ensureQueryData(query.details(id)),
    component: Page,
});
