import DP from "dompurify";
import { Marked, marked, RendererObject } from "marked";
import { logger } from "shared/utils/logger";

/** @private */
function _noimpl(input: unknown) {
    logger.warn("md plainTextRenderer found unsuported node: " + JSON.stringify(input));
    return "";
}

const plainTextRenderer: RendererObject = {
    paragraph({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    blockquote({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    br() {
        return "\n";
    },
    checkbox({ checked }) {
        return checked ? "[x]" : "[ ]";
    },
    heading({ tokens }) {
        return this.parser.parseInline(tokens) + "\n";
    },
    em({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    del({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    hr() {
        return "---\n";
    },
    link({ href, title }) {
        if (!title) return href;
        return `${title} (${href})`;
    },
    list({ items, ordered }) {
        return items.map((each, i) => `${ordered ? i + 1 + ") " : "*"} ${this.parser.parseInline(each.tokens)}`).join("\n") + "\n";
    },
    listitem({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    strong({ tokens }) {
        return this.parser.parseInline(tokens);
    },
    text({ text }) {
        return text;
    },
    space() {
        return "\n";
    },
    table: _noimpl,
    tablecell: _noimpl,
    code: _noimpl,
    codespan: _noimpl,
    image: _noimpl,
    html: _noimpl,
};

const plainTextMarked = new Marked({ renderer: plainTextRenderer });

export async function mdToTxt(md: string): Promise<string> {
    const txt = await Promise.resolve(plainTextMarked.parse(md)).then((it) => it.replaceAll("&#x20;", " "));

    // strip any trailing tags that aren't processed by marked such as `<u>`. See
    // https://github.com/markedjs/marked/issues/1693
    // https://github.com/markedjs/marked/pull/1872
    return DP.sanitize(txt, { ALLOWED_TAGS: ["#text"] });
}

export async function mdToHtml(md: string) {
    const dirty = await Promise.resolve(marked.parse(md));
    return DP.sanitize(dirty);
}
