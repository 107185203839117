import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type AuthStore = {
    session: {
        apiKey: string;
    } | null;
    user: {
        username: string;
    } | null;
    setSession: (args: { apiKey: string }) => void;
    setUser: (args: { username: string }) => void;
    resetStore: () => void;
};

const userStoreDefault = {
    session: null,
    user: null,
};

export const useAuthStore = create<AuthStore>()(
    devtools(
        // https://docs.pmnd.rs/zustand/integrations/persisting-store-data#options
        persist(
            immer((set) => {
                return {
                    ...userStoreDefault,
                    setSession: (session) => set({ session }),
                    setUser: (user) =>
                        set((store) => {
                            store.user = { ...store.user, ...user };
                        }),
                    resetStore: () => {
                        set(userStoreDefault);
                        useAuthStore.persist.clearStorage();
                    },
                };
            }),
            {
                name: "emns.store.auth",
                storage: createJSONStorage(() => localStorage),
                partialize: (store) => ({
                    session: store.session,
                    user: store.user,
                }),
            },
        ),
        { enabled: import.meta.env.VITE_ENVIRONMENT !== "prod", name: "Auth" },
    ),
);
