import {
    ActionIcon,
    Box,
    Checkbox,
    Combobox,
    Group,
    Input,
    InputBase,
    Loader,
    LoadingOverlay,
    Stack,
    Text,
    Tooltip,
    useCombobox,
    useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronRight } from "@tabler/icons-react";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Suspense, useState } from "react";
import { httpPostGraphql } from "shared/api/httpClient";
import { topicsQueries, topicsQueryOptions } from "shared/graphql/topics";
import { useColorMode } from "shared/hooks/useColorMode";
import { qk } from "shared/utils/qk";
import { TopicLikeFragment } from "src/gql/graphql";

export const TopicNode = ({
    topic,
    initialExpandedNodes,
    checkboxMode,
    checkedTopicIds,
    shouldRenderCheckbox,
    shouldCheckRequiredTopics,
    checkboxOnChange,
    disabledPredicate,
    disabledTooltipLabel,
}: {
    topic: TopicLikeFragment;
    initialExpandedNodes: string[];
    checkboxMode?: "controlled" | "uncontrolled"; // defaults to uncontrolled
    checkedTopicIds: string[];
    shouldRenderCheckbox: (topic: TopicLikeFragment) => boolean;
    shouldCheckRequiredTopics?: true;
    checkboxOnChange: (id: string, checked: boolean) => void;
    disabledPredicate?: (topic: TopicLikeFragment) => boolean;
    disabledTooltipLabel?: string;
}) => {
    const theme = useMantineTheme();
    const { isDark } = useColorMode();
    const [opened, { toggle }] = useDisclosure(initialExpandedNodes.includes(topic.id));
    const {
        data: {
            topic: { children },
        },
        isLoading,
    } = useSuspenseQuery({
        queryKey: qk("topic", "id", topic.id, "children"),
        queryFn: () => httpPostGraphql(topicsQueries.topicChildrenQuery, { id: topic.id }),
    });

    function isChecked() {
        if (checkedTopicIds.includes(topic.id)) return true;
        if (topic.isCategory && children.some((it) => !it.isCategory))
            return children.filter((c) => !c.isCategory).every((c) => checkedTopicIds.includes(c.id));
        return topic.isRequired && shouldCheckRequiredTopics;
    }

    return (
        <Box pos="relative">
            <LoadingOverlay visible={isLoading} />
            <Stack gap={0} style={{ display: "inline-flex" }} w="min-content">
                <Group gap={0} w="min-content" p="sm" wrap="nowrap" style={{ whiteSpace: "nowrap" }}>
                    <ActionIcon
                        display={topic.isCategory && (children.length ?? 0) > 0 ? undefined : "none"}
                        variant="transparent"
                        color="text"
                        onClick={toggle}
                    >
                        <IconChevronRight
                            size={16}
                            style={{ transition: "transform 0.5s", transform: opened ? "rotate(90deg)" : "rotate(0deg)" }}
                        />
                    </ActionIcon>
                    {shouldRenderCheckbox(topic) && (
                        <Tooltip disabled={!disabledPredicate?.(topic)} label={disabledTooltipLabel}>
                            <Checkbox
                                disabled={disabledPredicate?.(topic)}
                                defaultChecked={checkboxMode != "controlled" ? isChecked() : undefined}
                                checked={checkboxMode == "controlled" ? isChecked() : undefined}
                                onChange={(e) => checkboxOnChange(topic.id, e.target.checked)}
                                pr="sm"
                            />
                        </Tooltip>
                    )}
                    <Text>{topic.name}</Text>
                </Group>
                <Suspense fallback={<Loader />}>
                    {opened && (
                        <Stack
                            gap={0}
                            w="fit-content"
                            style={{
                                borderLeft: `var(--mantine-spacing-md) solid ${isDark ? theme.colors.dark[5] : theme.colors.gray[1]}`,
                                overflow: "auto",
                            }}
                        >
                            <Box>
                                {children.map((c) => (
                                    <TopicNode
                                        key={c.id}
                                        initialExpandedNodes={initialExpandedNodes}
                                        topic={c}
                                        shouldRenderCheckbox={shouldRenderCheckbox}
                                        shouldCheckRequiredTopics={shouldCheckRequiredTopics}
                                        checkboxOnChange={checkboxOnChange}
                                        checkedTopicIds={checkedTopicIds}
                                        checkboxMode={checkboxMode}
                                        disabledPredicate={disabledPredicate}
                                        disabledTooltipLabel={disabledTooltipLabel}
                                    />
                                ))}
                            </Box>
                        </Stack>
                    )}
                </Suspense>
            </Stack>
        </Box>
    );
};

export const CategoryParentSelect = ({ parentId, setParentId }: { parentId: string; setParentId: (id: string) => void }) => {
    const combobox = useCombobox();
    const [showTopicTree, setShowTopicTree] = useState(false);

    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { data, isLoading } = useQuery({ ...topicsQueryOptions.topic(parentId) });

    const TopicModal = () => {
        return (
            <Stack gap={0}>
                {rootTopic.children.map((t) => (
                    <TopicNode
                        key={t.id}
                        topic={t}
                        initialExpandedNodes={rootTopic.children.map((t) => t.id)}
                        checkedTopicIds={[parentId]}
                        shouldRenderCheckbox={(topic) => topic.isCategory}
                        checkboxOnChange={(id) => {
                            setParentId(id);
                            setShowTopicTree(false);
                        }}
                    />
                ))}
            </Stack>
        );
    };

    return (
        <>
            <Combobox store={combobox}>
                <Combobox.Target>
                    <InputBase
                        component="button"
                        type="button"
                        pointer
                        rightSection={<Combobox.Chevron />}
                        onClick={() => setShowTopicTree(true)}
                        rightSectionPointerEvents="none"
                        label="Parent Category"
                        withAsterisk
                    >
                        {data?.topic.name || (isLoading ? <Loader /> : <Input.Placeholder>Select parent...</Input.Placeholder>)}
                    </InputBase>
                </Combobox.Target>
            </Combobox>
            {showTopicTree && (
                <Stack mah={300} style={{ overflowY: "auto" }}>
                    <Suspense fallback={<Loader />}>
                        <TopicModal />
                    </Suspense>
                </Stack>
            )}
        </>
    );
};
