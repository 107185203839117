import { ActionIcon, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import { Content } from "shared/components/global/Content";
import { GroupTreeCombobox } from "shared/components/organization/groups";
import { useAddUserToGroup, useRemoveUserFromGroup } from "shared/graphql/groups";
import { useUserGroups } from "shared/graphql/users";
import { useUserId } from "shared/stores/oidc";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";

const GroupsList = () => {
    const { userId } = useUserId();
    const { groups } = useUserGroups();
    const { removeUser } = useRemoveUserFromGroup();

    return groups.map((g) => (
        <Stack key={g.id}>
            <Group justify="space-between">
                <Title order={3} pl="sm">
                    {g.name}
                </Title>
                {g.internalJoinable && (
                    <ActionIcon
                        variant="transparent"
                        onClick={() => {
                            removeUser({ userId, groupId: g.id });
                            notify.show.success({ message: "Successfully left group" });
                        }}
                    >
                        <IconX size={16} />
                    </ActionIcon>
                )}
            </Group>
            <Divider />
        </Stack>
    ));
};

const JoinGroupsContainer = () => {
    const { userId } = useUserId();
    const { addUserAsync } = useAddUserToGroup();
    const { removeUserAsync } = useRemoveUserFromGroup();
    const { groups: userGroups } = useUserGroups();

    return (
        <GroupTreeCombobox
            initialSelectedGroups={userGroups}
            onChange={async (groups) => {
                const groupsToAdd = groups.filter((it) => !userGroups.some((g) => g.id == it.id));
                const groupsToRemove = userGroups.filter((it) => !groups.some((g) => g.id == it.id));
                await Promise.all([
                    ...groupsToAdd.map((g) => addUserAsync({ userId, groupId: g.id })),
                    ...groupsToRemove.map((g) => removeUserAsync({ userId, groupId: g.id })),
                ])
                    .then((data) => {
                        if (groupsToAdd.length > 0) notify.show.success({ message: "Successfully joined group(s)" });
                        if (groupsToRemove.length > 0) notify.show.success({ message: "Successfully left group(s)" });

                        return data;
                    })
                    .then(() => {
                        return Promise.all([
                            qk.invalidate("user", "id", userId),
                            ...groupsToAdd.concat(groupsToRemove).map((g) => qk.invalidate("group", "id", g.id, "members")),
                        ]);
                    });
            }}
            target={(onClick) => (
                <Button
                    variant="default"
                    leftSection={<IconPlus size={16} />}
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        onClick();
                    }}
                >
                    Join Groups
                </Button>
            )}
            shouldRenderCheckbox={(group) =>
                group.internalJoinable && group.parents.some((g) => userGroups.some((userG) => userG.id == g.id))
            }
            disabledPredicate={(g) => g.synchronized}
        />
    );
};

export const GroupsContainer = () => {
    return (
        <Content paper>
            <Content.Heading>
                <Title order={3} id="groups">
                    Groups
                </Title>
            </Content.Heading>

            <GroupsList />
            <JoinGroupsContainer />
        </Content>
    );
};
