import { ActionIcon, Badge, Button, Divider, Group, Modal, Stack, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconDownload, IconEdit, IconPlus, IconTrash, IconX } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Content } from "shared/components/global/Content";
import { useAddUserAddress, useEditUserAddress, useRemoveUserAddress } from "shared/graphql/customer";
import { userQueryOptions } from "shared/graphql/users";
import { useUserId } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";

const AddressDetails = ({ setEditingId }: { setEditingId: (id: string) => void }) => {
    const userInfo = useUserId();
    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userInfo), select: (data) => data?.user.addresses });

    return addresses?.map((a) => {
        return (
            <Stack key={a.id}>
                <Group justify="space-between">
                    <div>
                        <Group gap={0}>
                            <Title order={5}>{a.name}</Title>

                            <ActionIcon variant="transparent" color="text" onClick={() => setEditingId(a.id)}>
                                <IconEdit size={16} />
                            </ActionIcon>
                        </Group>
                        <Text size="lg">{`${a.street}, ${a.city}, ${a.state} ${a.zip}`}</Text>
                    </div>
                </Group>
                <Divider />
            </Stack>
        );
    });
};

const EditLocationForm = ({ onSuccess, addressId }: { addressId: string; onSuccess: () => void }) => {
    const userInfo = useUserId();
    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userInfo), select: (data) => data?.user.addresses });

    const location = addresses?.find((a) => a.id == addressId)!;
    const form = useForm({
        mode: "uncontrolled",
        initialValues: {
            name: location.name,
            street: location.street,
            street2: location.street2,
            city: location.city,
            state: location.state,
            zip: location.zip,
        },
        validate: {
            name: (n) => (n.length < 1 ? "Invalid name" : null),
            street: (s) => (s.length < 1 ? "Invalid street" : null),
            city: (c) => (c.length < 1 ? "Invalid city" : null),
            state: (s) => (s.length < 1 ? "Invalid state" : null),
            zip: (z) => (z.length < 5 ? "Invalid zip code" : null),
        },
    });

    const { editUserAddressAsync } = useEditUserAddress();
    const { removeUserAddressAsync } = useRemoveUserAddress();

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.stopPropagation();
                editUserAddressAsync({
                    userId: userInfo.userId,
                    addressId,
                    address: {
                        name: data.name,
                        street: data.street,
                        street2: data.street2,
                        city: data.city,
                        state: data.state,
                        zip: data.zip,
                    },
                })
                    .then(onSuccess)
                    .catch(logger.error);
            })}
        >
            <Stack>
                <TextInput label="Name" placeholder="Enter name..." withAsterisk key={form.key("name")} {...form.getInputProps("name")} />
                <TextInput
                    label="Address"
                    placeholder="Enter address..."
                    withAsterisk
                    key={form.key("street")}
                    {...form.getInputProps("street")}
                />
                <TextInput
                    label="Address Line 2"
                    placeholder="Enter address..."
                    key={form.key("street2")}
                    {...form.getInputProps("street2")}
                />
                <Group wrap="nowrap">
                    <TextInput
                        label="City"
                        placeholder="Enter city..."
                        withAsterisk
                        key={form.key("city")}
                        {...form.getInputProps("city")}
                    />
                    <TextInput
                        label="State"
                        placeholder="Enter state..."
                        withAsterisk
                        key={form.key("state")}
                        {...form.getInputProps("state")}
                    />
                    <TextInput label="Zip" placeholder="Enter zip..." withAsterisk key={form.key("zip")} {...form.getInputProps("zip")} />
                </Group>
            </Stack>
            <Button
                fullWidth
                mt="md"
                leftSection={<IconTrash size={16} />}
                color="red"
                onClick={() => void removeUserAddressAsync({ userId: userInfo.userId, addressId: addressId }).then(onSuccess)}
            >
                Remove
            </Button>
            <Button fullWidth mt="md" leftSection={<IconPlus size={16} />} type="submit" color="green" disabled={!form.isDirty()}>
                Update
            </Button>
        </form>
    );
};

const AddLocationForm = ({ onSuccess }: { onSuccess: () => void }) => {
    const { userId } = useUserId();
    const { addUserAddressAsync } = useAddUserAddress();

    const form = useForm({
        mode: "uncontrolled",
        initialValues: { name: "", street: "", street2: "", city: "", state: "", zip: "" },
        validate: {
            name: (n) => (n.length < 1 ? "Invalid name" : null),
            street: (s) => (s.length < 1 ? "Invalid street" : null),
            city: (c) => (c.length < 1 ? "Invalid city" : null),
            state: (s) => (s.length < 1 ? "Invalid state" : null),
            zip: (z) => (z.length < 5 ? "Invalid zip code" : null),
        },
    });

    return (
        <form
            onSubmit={form.onSubmit((data, e) => {
                e?.stopPropagation();
                addUserAddressAsync({
                    userId,
                    address: {
                        name: data.name,
                        street: data.street,
                        street2: data.street2,
                        city: data.city,
                        state: data.state,
                        zip: data.zip,
                    },
                })
                    .then(onSuccess)
                    .catch(logger.error);
            })}
        >
            <Stack>
                <TextInput label="Name" placeholder="Enter name..." withAsterisk key={form.key("name")} {...form.getInputProps("name")} />
                <TextInput
                    label="Address"
                    placeholder="Enter address..."
                    withAsterisk
                    key={form.key("street")}
                    {...form.getInputProps("street")}
                />
                <TextInput
                    label="Address Line 2"
                    placeholder="Enter address..."
                    key={form.key("street2")}
                    {...form.getInputProps("street2")}
                />
                <Group wrap="nowrap">
                    <TextInput
                        label="City"
                        placeholder="Enter city..."
                        withAsterisk
                        key={form.key("city")}
                        {...form.getInputProps("city")}
                    />
                    <TextInput
                        label="State"
                        placeholder="Enter state..."
                        withAsterisk
                        key={form.key("state")}
                        {...form.getInputProps("state")}
                    />
                    <TextInput label="Zip" placeholder="Enter zip..." withAsterisk key={form.key("zip")} {...form.getInputProps("zip")} />
                </Group>
            </Stack>
            <Group justify="flex-end">
                <Button fullWidth mt="md" leftSection={<IconDownload size={16} />} type="submit">
                    Add
                </Button>
            </Group>
        </form>
    );
};

export const LocationsContainer = () => {
    const [addModalOpened, { open: openAddModal, close: closeAddModal }] = useDisclosure();
    const [editModalOpened, { open: openEditModal, close: closeEditModal }] = useDisclosure();
    const [editingId, setEditingId] = useState<string>();
    const userInfo = useUserId();

    const { data: addresses } = useSuspenseQuery({ ...userQueryOptions(userInfo), select: (data) => data?.user.addresses });

    useEffect(() => {
        if (!!editingId) openEditModal();
        else if (editModalOpened) closeEditModal();
    }, [editingId]);

    const AddModal = () => (
        <Modal
            centered
            opened={addModalOpened}
            onClose={() => {
                closeAddModal();
            }}
            withCloseButton={false}
        >
            <Modal.Header p={0}>
                <Group justify="space-between" w="100%">
                    <Text fw={600} fz="h3">
                        Add Address
                    </Text>
                    <ActionIcon variant="transparent" color="text" onClick={closeAddModal} autoFocus={false}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
            </Modal.Header>
            <AddLocationForm
                onSuccess={() => {
                    setEditingId(undefined);
                    closeAddModal();
                }}
            />
        </Modal>
    );

    const EditModal = ({ addressId }: { addressId?: string }) => (
        <Modal
            centered
            opened={editModalOpened}
            onClose={() => {
                closeEditModal();
                setEditingId(undefined);
            }}
            withCloseButton={false}
        >
            <Modal.Header p={0}>
                <Group justify="space-between" w="100%">
                    <Text fw={600} fz="h3">
                        Edit Address
                    </Text>
                    <ActionIcon variant="transparent" color="text" onClick={closeEditModal} autoFocus={false}>
                        <IconX size={16} />
                    </ActionIcon>
                </Group>
            </Modal.Header>
            {addressId && (
                <EditLocationForm
                    onSuccess={() => {
                        setEditingId(undefined);
                        closeAddModal();
                    }}
                    addressId={addressId}
                />
            )}
        </Modal>
    );

    return (
        <Content paper>
            <Content.Heading>
                <Title order={3} id="locations">
                    Locations
                </Title>
                <Badge variant="light" radius="sm">
                    Max 5
                </Badge>
            </Content.Heading>

            <AddressDetails setEditingId={setEditingId} />
            <Button w="min-content" disabled={(addresses?.length ?? 0) > 4} onClick={openAddModal}>
                Add Address
            </Button>
            <AddModal />
            <EditModal addressId={editingId} />
        </Content>
    );
};
