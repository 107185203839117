import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Checkbox,
    Divider,
    Group,
    Loader,
    Paper,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useInfiniteQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { GroupDetailsForm } from "shared/components/organization/groups";
import {
    groupsQueryOptions,
    infiniteMembersQueryOptions,
    useAddParentToGroup,
    useAddUsersToGroup,
    useRemoveParentFromGroup,
    useRemoveUserFromGroup,
    useUpdateGroup,
} from "shared/graphql/groups";
import { infiniteUserQueryOptions } from "shared/graphql/users";
import { useIsMobile } from "shared/hooks/useIsMobile";
import { usePermissionCheck } from "shared/stores/oidc";
import { logger } from "shared/utils/logger";
import { some } from "shared/utils/maybe";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { GroupLikeFragment, MemberLikeFragment, Permission } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { router } from "src/router";

const AddMembersModal = ({ groupId, initialMembers }: { groupId: string; initialMembers: string[] }) => {
    const [newMembers, setNewMembers] = useState<string[]>([]);
    const [usersSearchQuery, setUsersSearchQuery] = useDebouncedState("", 200);

    const { data } = useInfiniteQuery(
        infiniteUserQueryOptions({ nameQuery: some(usersSearchQuery).takeIf((it) => it.length > 0) ?? undefined }),
    );
    const members = data?.pages.flat();
    const { addUsers } = useAddUsersToGroup();

    return (
        <Stack>
            <TextInput label="Search Users" placeholder="Enter a name..." onChange={(e) => setUsersSearchQuery(e.currentTarget.value)} />
            <Stack mah="50dvh" style={{ overflowY: "auto" }}>
                {!!members ? (
                    members.map((m) => {
                        const checked = newMembers.includes(m.userId) || initialMembers.includes(m.userId);
                        return (
                            <Checkbox
                                key={m.userId}
                                label={m.contactInfo.name ?? "Unknown user"}
                                description={!!m.contactInfo.loginEmail ? m.contactInfo.loginEmail : ""}
                                size="md"
                                checked={checked}
                                disabled={initialMembers.includes(m.userId)}
                                onChange={() =>
                                    setNewMembers(checked ? newMembers.filter((id) => id != m.userId) : newMembers.concat(m.userId))
                                }
                            />
                        );
                    })
                ) : (
                    <Loader />
                )}
                <LoadMoreTrigger query={infiniteUserQueryOptions()} />
            </Stack>
            <Divider />
            <Button
                w="100%"
                disabled={newMembers.length == 0}
                onClick={() => {
                    addUsers({ groupId, userIds: newMembers });
                    notify.show.success("Added user to group");
                    modals.closeAll();
                }}
            >
                Add
            </Button>
        </Stack>
    );
};

const Member = ({
    member,
    canChangeMembership,
    onRemove,
}: {
    member: MemberLikeFragment;
    canChangeMembership: boolean;
    onRemove: () => void;
}) => {
    return (
        <Group key={member.id}>
            <Stack gap={0}>
                <Text>{member.name ?? "Unknown user"}</Text>
                <Text fz="sm">{member.loginEmail}</Text>
            </Stack>
            <ActionIcon variant="transparent" color="text" size="sm" disabled={!canChangeMembership} onClick={onRemove}>
                <IconX size={16} />
            </ActionIcon>
        </Group>
    );
};

const MemberDetails = ({ groupId }: { groupId: string }) => {
    const canChangeMembership = usePermissionCheck(Permission.GroupUsersManage, groupId);
    const { removeUser } = useRemoveUserFromGroup();
    const { data } = useSuspenseInfiniteQuery(infiniteMembersQueryOptions(groupId));
    const members = data?.pages.flat();

    return (
        <Content>
            <Group>
                <Title order={3}>Members</Title>
                <Tooltip label="You do not have permission to add members to this group" disabled={canChangeMembership}>
                    <Button
                        variant="default"
                        leftSection={<IconPlus size={16} />}
                        onClick={() =>
                            modals.open({
                                title: "Add members",
                                centered: true,
                                children: <AddMembersModal groupId={groupId} initialMembers={members.map((m) => m.id)} />,
                            })
                        }
                        disabled={!canChangeMembership}
                    >
                        Add
                    </Button>
                </Tooltip>
            </Group>
            <Box style={{ flexGrow: 1 }}>
                <Stack style={{ overflowY: "auto" }}>
                    {members.length > 0 ? (
                        <>
                            {members.map((member) => (
                                <Member
                                    key={member.id}
                                    member={member}
                                    canChangeMembership={canChangeMembership}
                                    onRemove={() => {
                                        removeUser({ userId: member.id, groupId: groupId });
                                        Promise.all([qk.invalidate("group"), qk.invalidate("user", "id", member.id)])
                                            .then(() => notify.show.success("Removed user from group"))
                                            .catch(logger.error);
                                    }}
                                />
                            ))}
                            <LoadMoreTrigger query={infiniteMembersQueryOptions(groupId)} />
                        </>
                    ) : (
                        <Stack align="center">
                            <Text c="dimmed">No members found</Text>
                        </Stack>
                    )}
                </Stack>
            </Box>
        </Content>
    );
};

const GroupDetailsContainer = () => {
    const isMobile = useIsMobile();
    const { groupId } = Route.useParams();
    const canViewMembership = usePermissionCheck(Permission.GroupUsersView, groupId);

    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const {
        data: { group },
    } = useSuspenseQuery(groupsQueryOptions.groupDetails(groupId));

    const { addParentAsync } = useAddParentToGroup();
    const { updateGroupAsync } = useUpdateGroup();
    const { removeParentAsync } = useRemoveParentFromGroup();

    const form = useForm<GroupLikeFragment>({
        initialValues: {
            ...group,
        },
        validate: {
            name: (v) => (v.length == 0 ? "Invalid name" : null),
            parents: (v) => (group.id != rootGroup.id && v.length == 0 ? "Group must have at least one parent" : null),
        },
    });

    const { getValues, reset } = form;

    function handleSubmit(data: GroupLikeFragment) {
        const promises: Promise<unknown>[] = [];
        if (form.isDirty("name") || form.isDirty("internalJoinable")) {
            promises.push(updateGroupAsync({ internalJoinable: data.internalJoinable, name: data.name, groupId: data.id }));
        }
        if (form.isDirty("parents")) {
            const newParents = getValues().parents.filter((p) => !group.parents.some((g) => g.id == p.id));
            const removedParents = group.parents.filter((p) => !getValues().parents.some((g) => g.id == p.id));
            if (newParents.length) newParents.forEach((p) => promises.push(addParentAsync({ groupId: group.id, parentId: p.id })));
            if (getValues().parents.length === 0) promises.push(Promise.reject("Group must have at least one parent"));
            else if (removedParents.length)
                removedParents.forEach((p) => promises.push(removeParentAsync({ groupId: group.id, parentId: p.id })));
        }

        Promise.all(promises)
            .then(async () => {
                await Promise.all([qk.invalidate("group"), qk.invalidate("user")]);
                notify.show.success({ message: "Updated group details" });
                form.setInitialValues({
                    ...getValues(),
                });
                reset();
            })
            .catch(logger.error);
    }

    return (
        <Paper p={!isMobile ? "sm" : undefined} h="100%" mah="85dvh" style={{ flexGrow: 1, overflowY: "auto" }}>
            <Stack>
                <Content.Heading backable p={0} gap={0}>
                    <Anchor onClick={() => router.history.back()} c="var(--mantine-color-text)">
                        Back
                    </Anchor>
                </Content.Heading>
                <GroupDetailsForm group={group} handleSubmit={handleSubmit} form={form} />
                {canViewMembership && (
                    <>
                        <MemberDetails groupId={group.id} />
                    </>
                )}
            </Stack>
        </Paper>
    );
};

/**
 * @public
 */
export const Route = createFileRoute("/_auth/organization/groups/$groupId")({
    loader: ({ params: { groupId } }) => {
        queryClient.ensureQueryData(groupsQueryOptions.rootGroup).catch(logger.error);
        queryClient.ensureQueryData(groupsQueryOptions.groupDetails(groupId)).catch(logger.error);
    },
    component: GroupDetailsContainer,
});
