import { Stack, Text, TooltipProps } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { httpPostGraphql } from "shared/api/httpClient";
import { ComposeFormState } from "shared/components/message/compose/context";
import { useFormSubscription } from "shared/hooks/useFormSubscription";
import { permissionCheck, usePermissions } from "shared/stores/oidc";
import { graphql } from "src/gql";
import { Permission } from "src/gql/graphql";
import { match, P } from "ts-pattern";

type ComposeFormPermissionError = {
    type: "sender" | "group" | "topic";
    target: { id: string; name?: string };
};

function formatComposePermissionError(error: ComposeFormPermissionError): string {
    return match(error)
        .with({ type: "sender" }, () => "You do not have permission to send from the selected Sender")
        .with({ target: { name: P.string } }, ({ target: { name } }) => `You do not have permission to send to '${name}'`)
        .with({ type: "group" }, () => "You do not have permission to send to one or more groups")
        .with({ type: "topic" }, () => "You do not have permission to send to one or more topics")
        .exhaustive();
}

export function useComposeFormPermissionChecker(form: UseFormReturnType<ComposeFormState>) {
    const groups = useFormSubscription(form, "audience.groups");
    const topics = useFormSubscription(form, "audience.topics");
    const sender = useFormSubscription(form, "sender.id");

    const { permissions } = usePermissions();
    const { data: senderData } = useSuspenseQuery(queries.sender(sender));

    const errors = [
        ...groups
            .filter((grp) => !permissionCheck(permissions, Permission.GroupMessageSendTo, grp.id))
            .map((target) => ({ type: "group", target }) satisfies ComposeFormPermissionError),
        ...topics
            .filter((tpc) => !permissionCheck(permissions, Permission.TopicMessageSendTo, tpc.id))
            .map((target) => ({ type: "topic", target }) satisfies ComposeFormPermissionError),
        ...(!senderData.ok && !!sender ? [{ type: "sender", target: { id: sender } } satisfies ComposeFormPermissionError] : []),
    ];

    return {
        hasErrors: errors.length != 0,
        errors,
        getTooltipProps() {
            return {
                label: (
                    <Stack gap={0}>
                        {errors.map(formatComposePermissionError).map((it, i) => (
                            <Text key={i} size="sm">
                                {it}
                            </Text>
                        ))}
                    </Stack>
                ),
                disabled: errors.length == 0,
            } satisfies Partial<TooltipProps>;
        },
    };
}

const queries = {
    sender: (id: string) =>
        queryOptions({
            queryKey: ["useComposeFormPermissionChecker", id],
            queryFn: () =>
                httpPostGraphql(
                    graphql(`
                        query UseComposeFormPermissionCheckerSenderInfo($id: UUID!) {
                            sender(id: $id) {
                                id
                            }
                        }
                    `),
                    { id },
                ).catch(() => null),
            select: (data) => (!!data ? { ok: true, ...data.sender } : { ok: false }),
            enabled: !!id,
        }),
};
