import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Center,
    CloseButton,
    Combobox,
    Group,
    Input,
    InputBase,
    Loader,
    Popover,
    rem,
    ScrollArea,
    Select,
    Stack,
    Text,
    TextInput,
    Title,
    Tooltip,
    useCombobox,
    useMantineTheme,
} from "@mantine/core";
import { useDebouncedValue, useDisclosure, useMap } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconChevronDown, IconEdit, IconEye, IconEyeOff, IconSearch, IconTrash, IconX } from "@tabler/icons-react";
import { useQuery, useSuspenseInfiniteQuery, useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { MantineReactTable, type MRT_ColumnDef, MRT_RowSelectionState, useMantineReactTable } from "mantine-react-table";
import { Suspense, type UIEvent, useCallback, useRef, useState } from "react";
import { Content } from "shared/components/global/Content";
import { LoadMoreTrigger } from "shared/components/global/LoadMoreTrigger";
import { GroupNode } from "shared/components/organization/groups";
import { TopicNode } from "shared/components/organization/topics";
import { RelativeTime } from "shared/components/RelativeTime";
import { USERS_TABLE_ROW_HEIGHT_PX } from "shared/const";
import { groupsQueryOptions } from "shared/graphql/groups";
import { topicsQueryOptions } from "shared/graphql/topics";
import {
    infiniteRolesQueryOptions,
    infiniteUserQueryOptions,
    useAssignCustomerRole,
    useAssignGroupRole,
    useAssignTopicRole,
    useDeleteUser,
    useUnassignRole,
} from "shared/graphql/users";
import { useColorMode } from "shared/hooks/useColorMode";
import { permissionCheck, usePermissionCheck, usePermissions } from "shared/stores/oidc";
import { settlePromises, uniqueBy } from "shared/utils/fns";
import { logger } from "shared/utils/logger";
import { notify } from "shared/utils/notify";
import { qk } from "shared/utils/qk";
import { GroupLikeFragment, Permission, PermissionType } from "src/gql/graphql";
import { queryClient } from "src/queryClient";
import { match } from "ts-pattern";
import { z } from "zod";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const userSchema = z.object({
    userId: z.string(),
    contactInfo: z.object({
        name: z.string(),
        loginEmail: z.string().optional(),
    }),
    roles: z
        .object({
            id: z.string(),
            role: z.object({
                id: z.string(),
                name: z.string(),
                type: z.nativeEnum(PermissionType),
            }),
            group: z.object({ id: z.string() }).nullish(),
            topic: z.object({ id: z.string() }).nullish(),
        })
        .array(),
    groups: z
        .object({
            name: z.string(),
        })
        .array(),
    lastActive: z.string().datetime().nullish(),
});

type User = z.infer<typeof userSchema>;

const columns: MRT_ColumnDef<User>[] = [
    {
        accessorKey: "contactInfo",
        header: "Name",
        Cell: ({ cell }) => {
            const { name, loginEmail } = cell.getValue<User["contactInfo"]>();
            return (
                <Stack gap={0} h={USERS_TABLE_ROW_HEIGHT_PX} mah={USERS_TABLE_ROW_HEIGHT_PX} style={{ overflow: "auto" }} justify="center">
                    <Text fz="lg">{name == " " ? "No name" : name}</Text>
                    {!!loginEmail && <Text fz="sm">{loginEmail}</Text>}
                </Stack>
            );
        },
    },
    {
        accessorKey: "roles",
        header: "Roles",
        Cell: ({ cell, row }) => {
            const [popoverOpened, { close: closePopover, toggle: togglePopover }] = useDisclosure(false);

            const roles = cell.getValue<User["roles"]>().filter(uniqueBy(({ role: { id } }) => id));
            const roleBadges = roles.map((role) => (
                <Badge
                    key={role.role.id + (role.group?.id ?? "") + (role.topic?.id ?? "")}
                    color={!!role.group ? "blue" : !!role.topic ? "green" : "yellow"}
                    variant="outline"
                    component="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        closePopover();
                        modals.open({
                            title: "Edit Role",
                            children: <RoleEditModal user={row.original} role={role.role} />,
                        });
                    }}
                >
                    {role.role.name}
                </Badge>
            ));

            return (
                <Group h={USERS_TABLE_ROW_HEIGHT_PX} mah={USERS_TABLE_ROW_HEIGHT_PX} style={{ overflow: "auto" }}>
                    {roles.length == 0 ? (
                        <Badge
                            color="red"
                            variant="outline"
                            component="button"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                modals.open({ title: "Assign Role", children: <RoleAssignModal userIds={[row.original.userId]} /> })
                            }
                        >
                            None
                        </Badge>
                    ) : roles.length <= 2 ? (
                        roleBadges
                    ) : (
                        <Popover opened={popoverOpened} onChange={togglePopover} trapFocus withArrow shadow="xl">
                            <Popover.Target>
                                <Badge
                                    color="gray"
                                    variant="outline"
                                    component="button"
                                    onClick={togglePopover}
                                    style={{ cursor: "pointer" }}
                                >
                                    <Group gap="xs">
                                        View All <IconChevronDown size={16} />
                                    </Group>
                                </Badge>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <Group wrap="wrap" w="fit-content" maw={400} justify="center">
                                    {roleBadges}
                                </Group>
                            </Popover.Dropdown>
                        </Popover>
                    )}
                </Group>
            );
        },
    },
    {
        accessorKey: "groups",
        header: "Groups",
        Cell: ({ cell }) => {
            const groups = cell
                .getValue<User["groups"]>()
                .map((g) => g.name)
                .join(", ");
            return (
                <Center h={USERS_TABLE_ROW_HEIGHT_PX} mah={USERS_TABLE_ROW_HEIGHT_PX} style={{ display: "flex", alignItems: "center" }}>
                    <Text lineClamp={2}>{groups}</Text>
                </Center>
            );
        },
    },
    {
        accessorKey: "lastActive",
        header: "Last Active",
        size: 100,
        Cell: ({ cell }) => {
            const lastActive = cell.getValue<User["lastActive"]>();
            return !!lastActive ? <RelativeTime date={parseInt(lastActive)} /> : <Text>Never</Text>;
        },
    },
];

function roleMutationNotifyOpts() {
    async function invalidateAndCloseModal() {
        await qk.invalidate("user");
        modals.closeAll();
    }
    return {
        onSuccess: async () => {
            await invalidateAndCloseModal();
            notify.show.success({ message: "Roles updated" });
        },
        onSomeFailed: async (numFailed: number) => {
            await invalidateAndCloseModal();
            notify.show.warn({ message: `${numFailed} user(s) could not be updated` });
        },
        onAllFailed: async () => {
            await invalidateAndCloseModal();
            notify.show.error({ message: "Roles could not be updated" });
        },
    };
}

type FatProps = { selectedCount: number; onClear: () => void; onAssign: () => void; onRemove: () => void };

const FloatingActionToolbar = ({ selectedCount, onClear, onAssign, onRemove }: FatProps) => {
    const theme = useMantineTheme();
    const { isDark } = useColorMode();
    const [opened, { toggle }] = useDisclosure(true);

    return (
        <Group
            justify="center"
            p="xs"
            gap={0}
            pos="absolute"
            bottom={rem(20)}
            w="fit-content"
            style={{
                left: 0,
                right: 0,
                marginInline: "auto",
                backgroundColor: isDark ? theme.colors.dark[4] : theme.colors.gray[2],
                opacity: opened ? "95%" : "50%",
                transition: "opacity 1.5s",
                borderRadius: "0.5rem",
                overflow: "hidden",
                flexWrap: "nowrap",
            }}
        >
            <Group justify="center" ml={opened ? 0 : -482} style={{ transition: "margin-left 1s" }}>
                <Button leftSection={<IconTrash size={16} />} variant="subtle" onClick={onRemove}>
                    Remove User
                </Button>
                <Button leftSection={<IconEdit size={16} />} variant="subtle" onClick={onAssign}>
                    Assign Roles
                </Button>
                <Button maw={150} w={150} leftSection={<IconX size={16} />} onClick={onClear} variant="subtle">
                    {selectedCount} selected
                </Button>
                {opened ? (
                    <Tooltip label="Hide">
                        <Button variant="subtle" p="xs" onClick={toggle}>
                            <IconEyeOff size={16} />
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip label="Show">
                        <Button variant="subtle" p="xs" onClick={toggle}>
                            <IconEye size={16} />
                        </Button>
                    </Tooltip>
                )}
            </Group>
        </Group>
    );
};

const GroupAssignComponent = ({ userIds, selectedRoleId }: { userIds: string[]; selectedRoleId: string }) => {
    const { permissions } = usePermissions();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const { assignGroupRoleAsync } = useAssignGroupRole();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !permissionCheck(permissions, Permission.GroupRolesManage, g.id);
    }

    return (
        <Stack gap="xs">
            <Title order={4}>Select Group</Title>
            <Text>Select at least one group for this role.</Text>
            <Box mah="400" style={{ overflow: "auto" }}>
                <GroupNode
                    group={rootGroup}
                    initialExpand={true}
                    isDisabledPredicate={isDisabledPredicate}
                    renderLabel={(group) => <Text opacity={isDisabledPredicate(group) ? 0.4 : undefined}>{group.name}</Text>}
                    checkboxOnClick={(node) =>
                        setSelectedCheckboxes(
                            selectedCheckboxes.some((n) => n == node.id)
                                ? selectedCheckboxes.filter((n) => n != node.id)
                                : selectedCheckboxes.concat(node.id),
                        )
                    }
                    selectedCheckboxes={selectedCheckboxes}
                    shouldRenderCheckbox={(g) => !isDisabledPredicate(g)}
                />
            </Box>
            <Button
                w="100%"
                disabled={selectedCheckboxes.length == 0}
                onClick={() => {
                    const promises = userIds
                        .map((id) =>
                            selectedCheckboxes.map((groupId) => assignGroupRoleAsync({ userId: id, roleId: selectedRoleId, groupId })),
                        )
                        .flat();
                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const GroupEditComponent = ({
    user,
    selectedRoleId,
    initialGroupIds,
}: {
    user: User;
    selectedRoleId: string;
    initialGroupIds: string[];
}) => {
    const { permissions } = usePermissions();
    const {
        data: { rootGroup },
    } = useSuspenseQuery(groupsQueryOptions.rootGroup);
    const { assignGroupRoleAsync } = useAssignGroupRole();
    const { unassignRoleAsync } = useUnassignRole();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>(initialGroupIds);
    const groupDiff = useMap<string, "add" | "remove">();

    function isDisabledPredicate(g: GroupLikeFragment) {
        return !permissionCheck(permissions, Permission.GroupRolesManage, g.id);
    }

    return (
        <Stack gap="xs">
            <Title order={4}>Select Group</Title>
            <Text>Select at least one group for this role.</Text>
            <Box mah="400" style={{ overflow: "auto" }}>
                <GroupNode
                    group={rootGroup}
                    initialExpand={true}
                    isDisabledPredicate={isDisabledPredicate}
                    renderLabel={(group) => <Text opacity={isDisabledPredicate(group) ? 0.4 : undefined}>{group.name}</Text>}
                    checkboxOnClick={(node) => {
                        if (selectedCheckboxes.some((n) => n == node.id)) {
                            setSelectedCheckboxes(selectedCheckboxes.filter((n) => n != node.id));
                            if (initialGroupIds.includes(node.id)) groupDiff.set(node.id, "remove");
                            else if (groupDiff.has(node.id)) groupDiff.delete(node.id);
                        } else {
                            setSelectedCheckboxes(selectedCheckboxes.concat(node.id));
                            if (!initialGroupIds.includes(node.id)) groupDiff.set(node.id, "add");
                            else if (groupDiff.has(node.id)) groupDiff.delete(node.id);
                        }
                    }}
                    selectedCheckboxes={selectedCheckboxes}
                    shouldRenderCheckbox={(g) => !isDisabledPredicate(g)}
                />
            </Box>
            <Button
                w="100%"
                disabled={groupDiff.size == 0}
                onClick={() => {
                    const promises = Array.from(groupDiff).map(([id, op]) => {
                        if (op == "add") return assignGroupRoleAsync({ userId: user.userId, roleId: selectedRoleId, groupId: id });
                        else {
                            const userRoleId = user.roles.find((r) => r.role.id == selectedRoleId && r.group?.id == id)?.id;
                            if (!userRoleId)
                                return Promise.reject(
                                    `userRoleId not found on user ${user.userId} for role ${selectedRoleId} for group ${id}`,
                                );
                            else return unassignRoleAsync({ userId: user.userId, userRoleId: userRoleId! });
                        }
                    });

                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const TopicAssignComponent = ({ userIds, selectedRoleId }: { userIds: string[]; selectedRoleId: string }) => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { assignTopicRoleAsync } = useAssignTopicRole();
    const { permissions } = usePermissions();

    const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);

    return (
        <Stack gap="xs">
            <Title order={4}>Select Topic</Title>
            <Text>Select at least one topic for this role.</Text>
            <Stack gap={0} mah={rem(400)} style={{ overflow: "auto" }}>
                <TopicNode
                    checkedTopicIds={[]}
                    initialExpandedNodes={[rootTopic.id]}
                    topic={rootTopic}
                    shouldRenderCheckbox={(it) => permissionCheck(permissions, Permission.TopicRolesManage, it.id)}
                    checkboxOnChange={(id, checked) => {
                        if (checked) setSelectedCheckboxes(selectedCheckboxes.concat(id));
                        else setSelectedCheckboxes(selectedCheckboxes.filter((t) => t != id));
                    }}
                />
            </Stack>
            <Button
                w="100%"
                disabled={selectedCheckboxes.length == 0}
                onClick={() => {
                    const promises = userIds
                        .map((id) =>
                            selectedCheckboxes.map((topicId) => assignTopicRoleAsync({ userId: id, roleId: selectedRoleId, topicId })),
                        )
                        .flat();
                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const TopicEditComponent = ({
    user,
    selectedRoleId,
    initialTopicIds,
}: {
    user: User;
    selectedRoleId: string;
    initialTopicIds: string[];
}) => {
    const {
        data: { rootTopic },
    } = useSuspenseQuery(topicsQueryOptions.rootTopic);
    const { assignTopicRoleAsync } = useAssignTopicRole();
    const { unassignRoleAsync } = useUnassignRole();
    const { permissions } = usePermissions();

    const topicDiff = useMap<string, "add" | "remove">();

    return (
        <Stack gap="xs">
            <Title order={4}>Select Topic</Title>
            <Text>Select at least one topic for this role.</Text>
            <Stack gap={0} mah="400" style={{ overflow: "auto" }}>
                <TopicNode
                    initialExpandedNodes={[rootTopic.id]}
                    topic={rootTopic}
                    shouldRenderCheckbox={(it) => permissionCheck(permissions, Permission.TopicRolesManage, it.id)}
                    checkboxOnChange={(id, checked) => {
                        if (checked) {
                            if (!initialTopicIds.includes(id)) topicDiff.set(id, "add");
                            else if (topicDiff.has(id)) topicDiff.delete(id);
                        } else {
                            if (initialTopicIds.includes(id)) topicDiff.set(id, "remove");
                            else if (topicDiff.has(id)) topicDiff.delete(id);
                        }
                    }}
                    checkedTopicIds={initialTopicIds}
                />
            </Stack>
            <Button
                w="100%"
                disabled={topicDiff.size == 0}
                onClick={() => {
                    const promises = Array.from(topicDiff).map(([id, op]) => {
                        if (op == "add") return assignTopicRoleAsync({ userId: user.userId, roleId: selectedRoleId, topicId: id });
                        else {
                            const userRoleId = user.roles.find((r) => r.role.id == selectedRoleId && r.topic?.id == id)?.id;
                            if (!userRoleId)
                                return Promise.reject(
                                    `userRoleId not found on user ${user.userId} for role ${selectedRoleId} for topic ${id}`,
                                );
                            else return unassignRoleAsync({ userId: user.userId, userRoleId: userRoleId! });
                        }
                    });

                    settlePromises({
                        promises,
                        ...roleMutationNotifyOpts(),
                    }).catch(logger.error);
                }}
            >
                Save
            </Button>
        </Stack>
    );
};

const RoleAssignModal = ({ userIds }: { userIds: string[] }) => {
    const { assignCustomerRoleAsync } = useAssignCustomerRole();
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const [selectedRole, setSelectedRole] = useState<User["roles"][0]["role"]>();

    const {
        data: { pages },
    } = useSuspenseInfiniteQuery(infiniteRolesQueryOptions);
    const roles = pages.flat();

    const options = roles.map((role) => (
        <Combobox.Option value={role.id} key={role.id}>
            {role.name}
        </Combobox.Option>
    ));

    return (
        <Stack pos="relative">
            <Combobox
                onOptionSubmit={(val) => {
                    combobox.closeDropdown();
                    setSelectedRole(roles.find((r) => r.id == val));
                }}
                store={combobox}
            >
                <Combobox.Target>
                    <InputBase
                        component="button"
                        type="button"
                        label="Role"
                        pointer
                        withAsterisk
                        rightSection={<Combobox.Chevron />}
                        rightSectionPointerEvents="none"
                        onClick={() => combobox.toggleDropdown()}
                        onBlur={() => combobox.closeDropdown()}
                    >
                        {!!selectedRole ? selectedRole.name : <Input.Placeholder>Pick role</Input.Placeholder>}
                    </InputBase>
                </Combobox.Target>
                <Combobox.Dropdown>
                    <Combobox.Options>
                        <ScrollArea.Autosize type="scroll" mah={200}>
                            {options.length === 0 ? <Combobox.Empty>No available roles</Combobox.Empty> : options}
                            <LoadMoreTrigger query={infiniteRolesQueryOptions} />
                        </ScrollArea.Autosize>
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
            {!!selectedRole &&
                match(selectedRole.type)
                    .with(PermissionType.Customer, () => (
                        <Button
                            w="100%"
                            onClick={() => {
                                const promises = userIds.map((id) => assignCustomerRoleAsync({ userId: id, roleId: selectedRole.id }));
                                settlePromises({
                                    promises,
                                    ...roleMutationNotifyOpts(),
                                }).catch(logger.error);
                            }}
                        >
                            Save
                        </Button>
                    ))
                    .with(PermissionType.Group, () => (
                        <Suspense fallback={<Loader />}>
                            <GroupAssignComponent userIds={userIds} selectedRoleId={selectedRole.id} />
                        </Suspense>
                    ))
                    .with(PermissionType.Topic, () => (
                        <Suspense fallback={<Loader />}>
                            <TopicAssignComponent userIds={userIds} selectedRoleId={selectedRole.id} />
                        </Suspense>
                    ))
                    .exhaustive()}
        </Stack>
    );
};

const RoleEditModal = ({ user, role }: { user: User; role: User["roles"][0]["role"] }) => {
    const { unassignRoleAsync } = useUnassignRole();

    const initialList = match(role.type)
        .with(PermissionType.Customer, () => undefined)
        .with(PermissionType.Group, () => user.roles.filter((r) => r.role.id == role.id).map((r) => r.group!.id))
        .with(PermissionType.Topic, () => user.roles.filter((r) => r.role.id == role.id).map((r) => r.topic!.id))
        .exhaustive();

    return (
        <Stack>
            <Select label="Role" withAsterisk defaultValue={role.name} data={[role.name]} disabled />

            {match(role.type)
                .with(PermissionType.Customer, () => (
                    <Button
                        w="100%"
                        leftSection={<IconTrash size={16} />}
                        onClick={(e) => {
                            e.preventDefault();
                            const userRoleId = user.roles.find((it) => it.role.id == role.id)?.id;
                            if (!userRoleId) return;
                            settlePromises({
                                promises: [unassignRoleAsync({ userId: user.userId, userRoleId })],
                                ...roleMutationNotifyOpts(),
                            }).catch(notify.catch);
                        }}
                    >
                        Remove Role Assignment
                    </Button>
                ))
                .with(PermissionType.Group, () => (
                    <Suspense fallback={<Loader />}>
                        <GroupEditComponent user={user} selectedRoleId={role.id} initialGroupIds={initialList!} />
                    </Suspense>
                ))
                .with(PermissionType.Topic, () => (
                    <Suspense fallback={<Loader />}>
                        <TopicEditComponent user={user} selectedRoleId={role.id} initialTopicIds={initialList!} />
                    </Suspense>
                ))
                .exhaustive()}
        </Stack>
    );
};

const UsersDataTable = () => {
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const selectedUsers = Object.keys(rowSelection);

    const [userSearch, setUserSearch] = useState("");
    const [debouncedUserSearch] = useDebouncedValue(userSearch, 200);
    const [groupSearch, setGroupSearch] = useState("");
    const [debouncedGroupSearch] = useDebouncedValue(groupSearch, 200);
    const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
    const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

    const {
        data: { pages: rolePages },
    } = useSuspenseInfiniteQuery(infiniteRolesQueryOptions);
    const roles = rolePages.flat();

    const hasGroupManage = usePermissionCheck(Permission.GroupSubgroupManage);

    const { data: groupSearchResults, isLoading: groupSearchIsLoading } = useQuery(groupsQueryOptions.search(debouncedGroupSearch));

    const {
        data: { pages: userPages },
        isError,
        hasNextPage,
        fetchNextPage,
        isLoading,
        refetch,
    } = useSuspenseInfiniteQuery(
        infiniteUserQueryOptions({
            nameQuery: debouncedUserSearch.length > 0 ? debouncedUserSearch : undefined,
            groupId: selectedGroupId ?? undefined,
            roleId: selectedRoleId ?? undefined,
        }),
    );
    const users = userPages.flat();

    const { deleteUserAsync } = useDeleteUser();

    function openConfirmDeleteModal({ numUsers, onConfirm }: { numUsers: number; onConfirm: () => void }) {
        modals.openConfirmModal({
            title: `Delete ${numUsers} User(s)?`,
            centered: true,
            children: <Text>Are you sure you want to permanently delete the selected user(s)? This action cannot be undone.</Text>,
            labels: { confirm: `Delete ${numUsers} user(s)`, cancel: "Cancel" },
            onConfirm,
        });
    }

    function openRoleAssignModal() {
        modals.open({
            title: "Assign Role",
            centered: true,
            children: <RoleAssignModal userIds={selectedUsers} />,
        });
    }

    const table = useMantineReactTable({
        columns,
        data: users,
        enableColumnActions: false,
        enableBottomToolbar: false,
        enablePagination: false,
        enableRowSelection: true,
        enableSelectAll: false,
        enableRowVirtualization: true,
        enableSorting: false,
        enableTopToolbar: false,
        getRowId: (row) => row.userId,
        mantineTableContainerProps: {
            ref: tableContainerRef,
            style: { height: "70vh", maxHeight: "1900px", flexGrow: 1 },
            onScroll: (event: UIEvent<HTMLDivElement>) => fetchMore(event.target as HTMLDivElement),
        },
        state: {
            rowSelection,
            showAlertBanner: isError,
        },
        rowVirtualizerOptions: {
            overscan: 5,
        },
        onRowSelectionChange: setRowSelection,
    });

    const groupSearchCombobox = useCombobox({
        onDropdownClose: () => groupSearchCombobox.resetSelectedOption(),
    });

    const fetchMore = useCallback(
        (containerRefElement?: HTMLDivElement | null) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (scrollHeight - scrollTop - clientHeight < 200 && !isLoading && hasNextPage) {
                    fetchNextPage().catch(logger.error);
                }
            }
        },
        [fetchNextPage, isLoading, hasNextPage],
    );

    const GroupSearchCombobox = () => {
        const selectedGroup = groupSearchResults?.find((it) => it.id == selectedGroupId)?.name;
        return (
            <Combobox
                store={groupSearchCombobox}
                onOptionSubmit={(id) => {
                    setSelectedGroupId(id);
                    groupSearchCombobox.closeDropdown();
                }}
            >
                <Combobox.Target>
                    <InputBase
                        label="Group"
                        component="button"
                        type="button"
                        w={200}
                        pointer
                        rightSection={
                            groupSearchIsLoading ? (
                                <Loader size={18} />
                            ) : !!selectedGroup ? (
                                <CloseButton
                                    size="sm"
                                    onMouseDown={(event) => event.preventDefault()}
                                    onClick={() => setSelectedGroupId(null)}
                                    aria-label="Clear value"
                                />
                            ) : (
                                <Combobox.Chevron />
                            )
                        }
                        onClick={() => groupSearchCombobox.toggleDropdown()}
                        rightSectionPointerEvents={!selectedGroup ? "none" : undefined}
                        styles={{
                            input: {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            },
                        }}
                    >
                        {selectedGroup || <Input.Placeholder>Pick value</Input.Placeholder>}
                    </InputBase>
                </Combobox.Target>

                <Combobox.Dropdown>
                    <Combobox.Search
                        value={groupSearch}
                        onChange={(event) => setGroupSearch(event.currentTarget.value)}
                        placeholder="Search groups"
                        autoFocus
                    />
                    <Combobox.Options>
                        {groupSearchIsLoading ? (
                            <Combobox.Empty>Loading....</Combobox.Empty>
                        ) : (
                            groupSearchResults?.map((it) => (
                                <Combobox.Option value={it.id} key={it.id}>
                                    {it.name}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Combobox.Dropdown>
            </Combobox>
        );
    };

    return (
        <Stack pos="relative">
            <Group>
                <TextInput
                    leftSection={<IconSearch size={16} />}
                    label="Search Users"
                    placeholder="Enter a name..."
                    onChange={(data) => setUserSearch(data.currentTarget.value)}
                    value={userSearch}
                    rightSection={
                        userSearch.length > 0 ? (
                            <ActionIcon variant="transparent" onClick={() => setUserSearch("")}>
                                <IconX size={16} />
                            </ActionIcon>
                        ) : undefined
                    }
                />
                <Select
                    data={roles.map((r) => ({ value: r.id, label: r.name }))}
                    label="Role"
                    placeholder="Any"
                    clearable
                    rightSectionPointerEvents={selectedRoleId == null ? "none" : undefined}
                    onChange={setSelectedRoleId}
                />
                {hasGroupManage && <GroupSearchCombobox />}
            </Group>
            <MantineReactTable table={table} />
            {selectedUsers.length > 0 && (
                <FloatingActionToolbar
                    selectedCount={selectedUsers.length}
                    onAssign={() => openRoleAssignModal()}
                    onRemove={() =>
                        openConfirmDeleteModal({
                            numUsers: selectedUsers.length,
                            onConfirm: () => {
                                const promises = selectedUsers.map((userId) => deleteUserAsync({ userId }));
                                settlePromises({
                                    promises,
                                    onSuccess: async () => {
                                        await refetch();
                                        notify.show.success({ message: "Users deleted" });
                                    },
                                    onSomeFailed: () => notify.show.warn({ message: "Some users were not deleted" }),
                                    onAllFailed: () => notify.show.error({ message: "Could not delete user(s)" }),
                                }).catch(logger.error);
                            },
                        })
                    }
                    onClear={() => setRowSelection({})}
                />
            )}
        </Stack>
    );
};

const Page = () => {
    return (
        <Content paper>
            <Content.Heading backable>
                <Title order={2} fw="normal">
                    Users
                </Title>
            </Content.Heading>
            <UsersDataTable />
        </Content>
    );
};

/** @public */
export const Route = createFileRoute("/_auth/organization/users")({
    component: Page,
    loader: () =>
        Promise.allSettled([
            queryClient.prefetchInfiniteQuery(infiniteUserQueryOptions()),
            queryClient.prefetchInfiniteQuery(infiniteRolesQueryOptions),
            queryClient.ensureQueryData(groupsQueryOptions.rootGroup),
            queryClient.ensureQueryData(topicsQueryOptions.rootTopic),
        ]),
});
