import { ActionIcon, Button, Group, NumberInput, Space, Stack, Switch, Title } from "@mantine/core";
import { IconCircleFilled, IconX } from "@tabler/icons-react";
import { useEffect } from "react";
import { useComposeFormContext } from "shared/components/message/compose/context";
import { useFormState } from "shared/hooks/useFormState";
import { arr } from "shared/utils/array";
import { MessageSeverity } from "src/gql/graphql";

const MAX_ESCALATIONS = 2;

export const EscalationEditor = () => {
    const form = useComposeFormContext();
    const [severity] = useFormState(form, "severity");
    const [acknowledgable, setAcknowledgable] = useFormState(form, "acknowledgable");
    const [escalations, setEscalations] = useFormState(form, "escalations");

    function handleCreateEscalation() {
        setEscalations(escalations.concat([{ delayMin: 5 }]));
    }

    useEffect(() => {
        if (severity != MessageSeverity.Urgent) setAcknowledgable(false);
    }, [severity]);

    useEffect(() => {
        if (!acknowledgable) setEscalations([]);
    }, [acknowledgable]);

    return (
        <Stack pos={"relative"}>
            <Switch label="Enable Acknowledgments" {...form.getInputProps("acknowledgable", { type: "checkbox" })} />
            <Stack style={{ borderLeft: "dashed 1px" }} pl="md">
                <Group>
                    <IconCircleFilled size={10} style={{ position: "absolute", left: 0, transform: "translate(-45%, 0px)" }} />
                    <Title order={3}>Message Sent</Title>
                </Group>

                <Space h={"xl"} />

                {escalations.map((each, i) => (
                    <>
                        <Group>
                            <IconCircleFilled size={10} style={{ position: "absolute", left: 0, transform: "translate(-45%, 0px)" }} />
                            <Title order={3}>Escalation {i + 1}</Title>
                        </Group>

                        <Group align="flex-end" gap={"xs"}>
                            <NumberInput
                                label="Delay"
                                min={5}
                                suffix=" min"
                                value={each.delayMin}
                                onChange={(v) =>
                                    arr(escalations)
                                        .update({ to: { delayMin: +v }, where: (_, idx) => idx == i })
                                        .take((it) => setEscalations(it))
                                }
                            />
                            <ActionIcon variant="transparent" size={"input-sm"} onClick={() => setEscalations(escalations.toSpliced(i, 1))}>
                                <IconX size={16} />
                            </ActionIcon>
                        </Group>

                        <Space h={"xl"} />
                    </>
                ))}

                <Group>
                    <IconCircleFilled size={10} style={{ position: "absolute", left: 0, transform: "translate(-45%, 0px)" }} />
                    <Button
                        variant="default"
                        disabled={!acknowledgable || escalations.length >= MAX_ESCALATIONS}
                        onClick={handleCreateEscalation}
                    >
                        Add Escalation
                    </Button>
                </Group>
            </Stack>
        </Stack>
    );
};
